import { faExclamationTriangle, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorDisplay, ValidationErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import { useSessionHandler } from "hooks/useSessionHandler";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
import { BookingItemModel, CancelBookingApiResponse } from "WebApiClient";
import { BookingMangerTableHeader } from "./BookingManager.Table";
import { BookingMangerTableRowData } from "./BookingManager.Table.Row";
// import { BookingManagerSingleBookingState } from "../types/BookingManagerTypes";

interface BookingMangerTableRowConfirmCancelModalProps {
  Booking: BookingItemModel,
  OnUpdate: (data: BookingItemModel) => void
  Toggle: () => void
  IsOpen: boolean
}

const BookingMangerTableRowConfirmCancelModal: React.FC<BookingMangerTableRowConfirmCancelModalProps> = props => {
  const booking = props.Booking;
  const bookClient = useBookingClient();
  const [fetching, setFetching] = useState(false);
  const dispatch = useDispatch();
  const [response, setResponse] = useState<CancelBookingApiResponse | undefined>(undefined);
  const [errorOccured, setErrorOccured] = useState(false);
  const result = response?.result;
  const sessionHandler = useSessionHandler();
  const [ticketBookingBtn, setTicketBookingBtn] = useState(false);

  useEffect(() => {
    if (booking.latestVersion.generalInfo.bookingStatus == 16) {
      setTicketBookingBtn(true)
    }
  }, [booking.latestVersion.generalInfo.bookingStatus])

  function SubmitCancel() {
    const identifier = booking.latestVersion?.bookingIdentifier;
    if (identifier) {
      setFetching(true);
      bookClient.cancelBooking(identifier)
        .then(response => {
          setResponse(response);
          dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
          if (response.result) {
            setErrorOccured(false);
            if (response.result.updatedBooking) {
              props.OnUpdate(response.result.updatedBooking)
            }
            else {
              if (response.result!.validationResult.isValid) {
                setErrorOccured(true);
              }
            }
          }
          else {
            setErrorOccured(true);
          }
        })
        .catch(() => {
          setErrorOccured(true);
        })
        .finally(() => {
          setFetching(false);
          sessionHandler.RefreshSession();
        })
    }

  }
  const warnings = result?.warnings ? result.warnings : [];

  return (
    <React.Fragment>

      <Modal size="xl" centered toggle={props.Toggle} isOpen={(props.IsOpen || fetching)}>
        <ModalHeader toggle={props.Toggle}>Cancel booking</ModalHeader>
        <ModalBody>
          <div className="row">

            {fetching &&
              <div className="col-12">
                <WaitingCard />
              </div>
            }
            {!fetching &&
              <React.Fragment>
                {errorOccured &&
                  <ErrorDisplay isHide={true} data={response?.responseMetaData} />
                }
                {result &&
                  <Fragment>
                    {!result.validationResult.isValid &&
                      <div className="col-12 mb-2">
                        <ValidationErrorDisplay data={result.validationResult} />
                      </div>
                    }
                  </Fragment>
                }

                {warnings.length > 0 &&
                  <div className="col-12 mb-2">
                    <Alert color="warning">

                      <h4 className="alert-heading mb-2">
                        <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION
                      </h4>
                      <ul>
                        {warnings.map((e, i) =>
                          <li key={"warnings" + i}>{e}</li>
                        )}
                      </ul>
                    </Alert>
                  </div>
                }

                {result?.updatedBooking ?
                  <div className="col-12 mb-2">
                    <Alert color="success">Booking successfully cancelled.</Alert>
                  </div>
                  :
                  <Fragment>
                    <div className="col-12 mb-2">
                      <h4>Please confirm to cancel the following booking:</h4>
                    </div>
                    <div className="col-12 mb-2">
                      <div className="table-responsive">

                        <table className={`table table-sm table-bordered w-100 table-hover bg-white`}>
                          <BookingMangerTableHeader GetArrowIcon={() => <span></span>} SetOrdering={() => { }} ClassName="" ShowActions={false} />
                          <tbody>
                            <tr>
                              <BookingMangerTableRowData Booking={props.Booking} />
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {result?.updatedBooking ?
                      null :
                      <div className="col-12">
                        <Button color="danger" disabled={ticketBookingBtn} className="text-white" block onClick={SubmitCancel} ><FontAwesomeIcon style={{ "color": "white" }} icon={faTrash} /> YES, Cancel booking</Button>{' '}
                      </div>
                    }

                  </Fragment>
                }


              </React.Fragment>
            }
          </div>



        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={props.Toggle}>close</Button>
        </ModalFooter>


      </Modal>

    </React.Fragment>
  );


}

{/* {!props.Booking.DidCancelBooking && !isFetching && !props.Booking.CancelBookingError &&
            <React.Fragment>

              <div className="row">
                <div className="col">
                  <Alert color={!props.Booking.DidCancelBooking ? "info" : "success"}>
                    <h4 className="alert-heading">Are you SURE to cancel this booking?</h4>
                  </Alert>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Filekey</label>
                    <div className="col-sm-10">
                      <label className="form-control-plaintext">{props.Booking.Data.latestVersion!.generalInfo!.filekey}</label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Passenger</label>
                    <div className="col-sm-10">
                      <label className="form-control-plaintext">{props.Booking.Data.latestVersion!.generalInfo!.mainPassengerLastName}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Button color="danger" className="text-white" block onClick={() => props.CancelBooking(props.Booking)}><FontAwesomeIcon style={{ "color": "white" }} icon={faTrash} /> YES, Cancel booking</Button>{' '}
                </div>
              </div>
            </React.Fragment>

          }
          <div className="row">
            {isFetching &&
              <div className="col"><FontAwesomeIcon icon={faSpinner} spin /> Cancelling booking...</div>
            }
            {props.Booking.CancelBookingError && !isFetching &&
              <React.Fragment>

                <ErrorMessage
                  ErrorOccured={props.Booking.CancelBookingError}
                  ErrorMessages={[props.Booking.CancelBookingErrorMessage]}
                  TransactionID={props.Booking.CancelBookingTransactionID}
                />
                <Button color="danger" className="text-white" block onClick={() => props.CancelBooking(props.Booking)}><FontAwesomeIcon style={{ "color": "white" }} icon={faTrash} /> RETRY to Cancel booking</Button>{' '}
              </React.Fragment>
            }
            {props.Booking.DidCancelBooking &&
              <React.Fragment>
                <Alert color={"success"}>
                  <h4 className="alert-heading">Booking successfully cancelled.</h4>
                </Alert>
              </React.Fragment>

            }
          </div>
 */}













export default BookingMangerTableRowConfirmCancelModal



