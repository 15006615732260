import { createSlice } from '@reduxjs/toolkit'

const initialState = { value: [] }

export const upsellSlice = createSlice({
    name: 'upsellSlice',
    initialState,
    reducers: {
        upsell_Reducer(state, action) {
            state.value = action.payload
        },
    },
})

export const { upsell_Reducer } = upsellSlice.actions