import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AncillaryMatrix } from "components/AncillarySelection/AncillaryMatrix";
import {
  AncillarySelection,
  AssignablePassengerOption,
} from "components/AncillarySelection/AncillarySelection";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { BugReportModal } from "components/Shared/BugReportModal";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useAvailableFaresClient } from "hooks/useHttpClient";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Session_Reducer_PushTransaction } from "rootExports/SessionReducer";
import {
  AvailableFareModel,
  GetAncillariesApiResponse,
  ServiceModel,
  ServicePriceModel,
  ServiceSegmentModel,
} from "WebApiClient";

interface AncillariesModalProps {
  IsOpen: boolean;
  Toggle: () => void;
  SelectedConnections: number[];
  SetSelectedConnection: (legindex: number, connectionIndex: number) => void;
  Fare: AvailableFareModel;
  AddedServices: ServiceAddObj[];
  SetAddedServices(services: ServiceAddObj[]): void;
  Passengers: AssignablePassengerOption[];
  fetching?: any;
  error?: any;
  response?: any;
}

export const AncillariesModal: React.FC<AncillariesModalProps> = (props) => {
  return (
    <Modal
      size="xl"
      scrollable={true}
      isOpen={props.IsOpen}
      toggle={props.Toggle}
    >

   
      <ModalHeader toggle={props.Toggle}>Ancillaries</ModalHeader>
    
      <ModalBody>
        {props.IsOpen && (
          
          <AncillaryComponent
            Fare={props.Fare}
            SelectedConnections={props.SelectedConnections}
            SetSelectedConnection={props.SetSelectedConnection}
            AddedServices={props.AddedServices}
            SetAddedServices={props.SetAddedServices}
            Passengers={props.Passengers}
            fetching={props.fetching}
            error={props.error}
            response={props.response}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={props.Toggle}>
          close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

interface AncillaryComponentProps {
  SelectedConnections: number[];
  SetSelectedConnection: (legindex: number, connectionIndex: number) => void;
  Fare: AvailableFareModel;
  AddedServices: ServiceAddObj[];
  SetAddedServices(services: ServiceAddObj[]): void;
  Passengers: AssignablePassengerOption[];
  fetching?: any;
  error?: any;
  response?: any;
  setExtraAncCheck?: (newValue: boolean) => void;
}

export const AncillaryComponent: React.FC<AncillaryComponentProps> = (
  props
) => {
  const addedServices = props.AddedServices;
  const [expand, setExpand] = useState(false);
  const ToggleExpand = () => {
    setExpand(!expand);
  };

  const dispatch = useDispatch();
  const client = useAvailableFaresClient();

  const [flightTimes, setflightTimes] = useState(false);
  const ToggleFlightTimes = () => {
    setflightTimes(!flightTimes);
  };

  const [bugReportModalOpen, setBugReportOpen] = useState(false);
  const ToggleBugReportModal = () => {
    setBugReportOpen(!bugReportModalOpen);
  };

  const responseMetaData = props.response?.responseMetaData;
  const result = props.response?.result;

  useEffect(() => {
    setExpand(false);
  }, [props.Fare.fareIndex]);

  useEffect(() => {
    // ToggleExpand()
  }, []);

  function OnAddService(obj: ServiceAddObj) {
    let newServices: ServiceAddObj[] = [...addedServices];
    newServices.push(obj);
    props.SetAddedServices(newServices);
  }

  function OnRemoveService(obj: ServiceAddObj) {
    let legServices = [...addedServices];
    const filteredServices = legServices.filter((service) => {
      const shouldExclude =
        service.Price.priceID === obj.Price.priceID &&
        service.PassengerIndex === obj.PassengerIndex &&
        service.Service.code === obj.Service.code &&
        service.Segments.every((segment) =>
          obj.Segments.some((objSegment) =>
            Object.entries(objSegment).every(
              ([key, value]) => (segment as any)[key] === value
            )
          )
        );

      // Include the service only if it should not be excluded
      return !shouldExclude;
    });
    props.SetAddedServices(filteredServices);
    // console.log(filteredServices);
    // let newServices: ServiceAddObj[] = [...addedServices];
    // newServices.splice(obj);
    props.SetAddedServices(filteredServices);
    // console.log(filteredServices);
  }

  function OnRemoveService1(serviceIndex: number) {
    let legServices = [...addedServices];
    legServices.splice(serviceIndex, 1);
    props.SetAddedServices(legServices);
  }

  function OnEditService(index: number, amount: number) {
    let newServices = [...addedServices];
  }
  
  return (
    <div className="">
      <div className="d-flex">
        {/* <div className="col-12 mb-2">
        <AncillaryDescription />
      </div> */}

        <div className="w-100 mx-auto">
          <div className="card">
            <div className="">
              <div className="row">
                {props.fetching && (
                  <div className="col-12 mb-2 ">
                    <WaitingCard />
                  </div>
                )}
                {props.error && (
                  <ErrorDisplay isHide={true} data={props.response?.responseMetaData} />
                )}
                {result && (
                  <div className="col-12 mb-2 mx-auto">
                    <AncillarySelection
                      SegmentOptions={[]}
                      Model={result}
                      OnAddService={OnAddService}
                      OnEditService={OnEditService}
                      AddedServices={props.AddedServices}
                      PassengerOptions={props.Passengers}
                      IncludedServices={props.Fare.includedServices}
                      OnRemoveService={OnRemoveService}
                      setExtraAncCheck={props.setExtraAncCheck}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="w-25">
          <AncillaryMatrix
            Legs={props.Fare.legs}
            Services={addedServices}
            SelectedConnections={props.SelectedConnections}
            AssignablePassengers={props.Passengers}
            OnRemoveService={OnRemoveService1}
            Currency={props.Fare.currency!}
            FarePrice={props.Fare.totalPrice}
          />
        </div> */}
      </div>
      <BugReportModal
        Toggle={ToggleBugReportModal}
        IsOpen={bugReportModalOpen}
        MetaData={responseMetaData}
      ></BugReportModal>
    </div>
  );
};

const AncillaryDescription: React.FC<{}> = (props) => {
  const [showDescription, setShowDescription] = useState(false);
  return (
    <div
      className="alert alert-info clickme"
      onClick={() => {
        setShowDescription(!showDescription);
      }}
    >
      <p>
        <FontAwesomeIcon icon={faInfoCircle} />{" "}
        <span className="font-weight-bold">
          How to manage ancillary services
        </span>{" "}
        <span className="float-end">
          <FontAwesomeIcon
            icon={showDescription ? faAngleDoubleUp : faAngleDoubleDown}
          />
        </span>{" "}
        <br />
      </p>
      <Collapse isOpen={showDescription}>
        <p>
          {" "}
          You can add services by selecting a service in the "View and select
          services" area per segment and passenger.<br></br>
          In the "Overview" section, you can see all added services per segment
          and passenger. You can view details and remove a service by selecting
          a service in the overview.
        </p>
        <p className="font-weight-bold">IMPORTANT</p>
        <ul>
          <li>
            Changing a flight connection will reset ALL selected services.
          </li>
          <li>
            A service can be linked to 1 or more segments and applies per
            individual passenger. A service which is linked to more than 1
            segment (e.g. baggage, which is usually linked to all segments in a
            flight) can only be added and removed for all linked segments.
          </li>
        </ul>
      </Collapse>
    </div>
  );
};

export interface ServiceAddObj {
  // Amount: number
  // Passenger: ServicePassengerModel,
  PassengerIndex: number;
  Segments: ServiceSegmentModel[];
  Price: ServicePriceModel;
  Service: ServiceModel;
}
