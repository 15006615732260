
export const GetErrorMessage = (errorCode: string, t: any): string => {
    switch (errorCode) {
        case "ERR_BACKEND_OFFLINE":
            return t("error.backendError");
        case "ERR_NETWORK":
            return t("error.networkError");
        case "ERR_PERMISSION":
            return t("error.permissionError");
        case "ERR_NOT_FOUND":
            return t("error.notFoundError");
        case "ERR_SERVER":
            return t("error.serverError");
        case "ERR_SESSION_EXPIRED":
            return t("error.sessionError");
        case "ERR_UNKNOWN":
        default:
            return t("error.defaultError");
    }
};