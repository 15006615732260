import { faExpandAlt, faSync, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BookingDetails from "components/BookingDetails/component/BookingDetails";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import { useSessionHandler } from "hooks/useSessionHandler";
import { useUserInformation } from "hooks/various";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
import { BookingDetailsApiResponse, BookingItemModel } from "WebApiClient";
// import { BookingManagerSingleBookingState } from "../types/BookingManagerTypes";

interface BookingDetailsModalProps {
  Booking: BookingItemModel,
  Toggle: () => void
  IsOpen: boolean,
  OnUpdate: (data: BookingItemModel) => void
}
const BookingDetailsModal: React.FC<BookingDetailsModalProps> = props => {
  const client = useBookingClient();
  const dispatch = useDispatch();
  const sessionHandler = useSessionHandler();
  const userInfo = useUserInformation();

  const [response, setResponse] = useState<BookingDetailsApiResponse | undefined>(undefined);
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const result = response?.result;
  const navigate = useNavigate();

  function OnRefresh() {
    setFetching(true);
    client.getBookingDetails(props.Booking.latestVersion.bookingIdentifier)
      .then(response => {
        dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
        setResponse(response);
        if (response.result) {
          setError(false);
        }
        else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setFetching(false);
      });
  }

  useEffect(() => {
    if (props.IsOpen) {
      OnRefresh();
    }
  }, [props.IsOpen])


  return (
    <React.Fragment>
      <Modal size="xl" centered toggle={props.Toggle} isOpen={(props.IsOpen)} scrollable={true}>
        <ModalHeader toggle={props.Toggle}>Booking details for <span className="font-weight-bold">{props.Booking.latestVersion.generalInfo.filekey}</span></ModalHeader>
        <ModalBody style={{ maxHeight: "calc(100vh - 200px)", minHeight: "calc(100vh - 200px)" }}>
          <div className="row">
            {fetching ?
              <div className="col-12 mb-2">
                <WaitingCard />

              </div> :
              <Fragment>

                {error &&
                  <div className="col-12 mb-2">
                    <ErrorDisplay isHide={true} data={response?.responseMetaData} />
                  </div>
                }
                <div className="col-12 mb-2">
                  {result &&
                    <BookingDetails Booking={result} />
                  }
                </div>
              </Fragment>
            }

          </div>
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={OnRefresh}><FontAwesomeIcon icon={faSync} /> Refresh</Button>
          <Button color="secondary" onClick={() => { navigate(`/auth/${userInfo.WebID}/${userInfo.Deeplink}/bookingmanager/details/${props.Booking.latestVersion.bookingIdentifier.cartId}/${props.Booking.latestVersion.bookingIdentifier.bookingId}`) }}><FontAwesomeIcon icon={faExpandAlt} /> Expand</Button>
          <Button color="primary" onClick={props.Toggle}><FontAwesomeIcon icon={faTimes} /> close</Button>
          {/* <Link to={} className="dropdown-item" ><FontAwesomeIcon icon={faExternalLink} /> Expand</Link> */}

        </ModalFooter>


      </Modal>

    </React.Fragment>
  );
}


export default BookingDetailsModal;


