import {
    faCheckCircle,
    faExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";
import _ from "lodash";

import { faLuggageCart } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, useRef, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import Tooltip from "@mui/material/Tooltip";
import { FareHeaderView } from "WebApiClient";
import IncServices from "components/Shared/IncServices";
import { SabreIconsEmoji } from "./IncludedServiceIcons/SabreIcons";
import { State } from 'rootExports/rootReducer';
import { useSelector } from "react-redux";

interface IncludedServicesProps {
    readonly Data: FareHeaderView;
    readonly Fareindex: number;
    readonly BaggageType: string;
    FareTypeInfo: any;
    mealCode: string;
}

const FareHeaderIncludedServices: React.FC<IncludedServicesProps> = (props) => {
    // console.log(props.Data.cabinClassCombined);
    const iconDataDefaultHeight: number = useSelector((state: State) => state.Session.FrontendSettings.iconDataSettings[0]?.defaultHeight);
    function checkBaggageTypeLength(baggageType: string) {
        // Extracting the numeric part using a regular expression
        var numericPart = baggageType.match(/\d+/);
        // Check if numericPart exists and return its length, otherwise return 0
        return numericPart ? numericPart[0].length : 0;
    }

    function checkBaggageType() {
        if (props.BaggageType == "01P") {
            return (
                <span className="baggageServicesSpanStyle">
                    <Tooltip
                        title={
                            <>
                                <span>{props.BaggageType}</span>
                            </>
                        }
                    >
                        <span>
                            <span className={checkBaggageTypeLength(props?.BaggageType) == 1 ? "baggageCodeInner1" : "baggageCodeInner"}>  </span>
                            {/* <span>{SabreIconsEmoji()?.["01P"]?.icon}</span> */}
                            <img style={{}} src={`${SabreIconsEmoji()?.["Checked baggage"]?.icon}`} height={`${iconDataDefaultHeight}px`} alt="hitchhiker" />
                        </span>
                    </Tooltip>
                </span>
            );
        }
        if (props.BaggageType == "02P") {
            return (
                <span className="baggageServicesSpanStyle">
                    <Tooltip
                        title={
                            <>
                                <span>{props.BaggageType}</span>
                            </>
                        }
                    >
                        <span>
                            {/* <span className={checkBaggageTypeLength(props?.BaggageType) == 1 ? "baggageCodeInner1" : "baggageCodeInner"}>
                                {
                                    extractNumbers(props.BaggageType)
                                }
                            </span> */}
                            {/* <span>{SabreIconsEmoji()?.["01P"]?.icon}</span> */}
                            <img style={{}} src={`${SabreIconsEmoji()?.["Checked baggage"]?.icon}`} height={`${iconDataDefaultHeight}px`} alt="hitchhiker" />
                        </span>
                    </Tooltip>
                </span>
            );
        }
        if (props.BaggageType == "25K") {
            return (
                <span className="baggageServicesSpanStyle">
                    <Tooltip
                        title={
                            <>
                                <span>{props.BaggageType}</span>
                            </>
                        }
                    >
                        <span>
                            {/* <span className={checkBaggageTypeLength(props?.BaggageType) == 1 ? "baggageCodeInner1" : "baggageCodeInner"}>
                                {extractNumbers(props.BaggageType)}
                            </span> */}
                            {/* <span>{SabreIconsEmoji()?.["01P"]?.icon}</span> */}
                            <img style={{}} src={`${SabreIconsEmoji()?.["Checked baggage"]?.icon}`} height={`${iconDataDefaultHeight}px`} alt="hitchhiker" />
                        </span>
                    </Tooltip>
                </span>
            );
        }
        if (props.BaggageType == "") {
            return (
                <span className="baggageServicesSpanStyle">
                    <Tooltip
                        title={
                            <>
                                <span>This fare has free baggage allowance for at least one connection</span>
                            </>
                        }
                    >
                        <span>
                            {/* <span>{SabreIconsEmoji()?.["01P"]?.icon}</span> */}
                            <img style={{}} src={`${SabreIconsEmoji()?.["Checked baggage"]?.icon}`} height={`${iconDataDefaultHeight}px`} alt="hitchhiker" />
                        </span>
                    </Tooltip>
                </span>
            );
        }
        return (
            <span className="baggageServicesSpanStyle">
                <Tooltip
                    title={
                        <>
                            <span>{props.BaggageType}</span>
                        </>
                    }
                >
                    <span >
                        {/* <span className={checkBaggageTypeLength(props?.BaggageType) == 1 ? "baggageCodeInner1" : "baggageCodeInner"}>
                            {props.BaggageType == "1P" ? "" :
                                props.BaggageType == "0K" ? "" :
                                    props.BaggageType == "01P" ? "" :
                                        props.BaggageType == "1PC" ? "" :
                                            extractNumbers(props.BaggageType)
                            }
                        </span> */}
                        {/* <span>{SabreIconsEmoji()?.["01P"]?.icon}</span> */}
                        <img style={{}} src={`${SabreIconsEmoji()?.["Checked baggage"]?.icon}`} height={`${iconDataDefaultHeight}px`} alt="hitchhiker" />
                    </span>
                </Tooltip>
            </span>
        );
    }

    //mealcode make array
    const mealCodeArray = _.split(props.mealCode, ",");

    // Extract unique hints
    const combinedHints = Array.from(
        new Map(
            props?.Data?.includedServices!
                .flatMap((item) => item.services!.map((service) => ({ hint: service.hint })))
                .map((obj) => [obj.hint, obj]) // Use the hint as the key
        ).values()
    );

    const getRightIcon = (code: string) => {
        // Assuming SabreIconsEmoji() returns the JSON object
        const data: string[] = Object.keys(SabreIconsEmoji());
        if (data.includes(code)) {
            // If code is found in data, return the code
            return code;
        } else {
            // If code is not found, return the first character of the code
            const firstCharacter = code.charAt(0);
            return firstCharacter;
        }
    };

    return (
        <React.Fragment>
            <div
                className="ancillaries align-bottom min-w-100px d-flex align-items-end"
                style={{
                    width: "118px",
                    position: 'relative',
                    flexWrap: 'wrap'
                }}>
                {/* {props.Data.includedServices!.length > 0 && <span> | </span>} */}
                <span style={{ display: 'flex', alignItems: 'start' }}>
                    <>
                        {props.BaggageType === "" ? (
                            props.Data.fareHasFreeBaggage == false ? (
                                <FreeBaggeIndicator HasFreeBaggage={props.Data.fareHasFreeBaggage} />
                            ) : (
                                checkBaggageType()
                            )
                        ) : (
                            checkBaggageType()
                        )}
                    </>
                    <Tooltip title={
                        <React.Fragment>
                            {
                                combinedHints.length && combinedHints!.map((sub: any, subIndex: any) => <span key={"mainSer_" + subIndex}>
                                    <li>
                                        {sub.hint}
                                    </li>
                                </span>
                                )}
                        </React.Fragment>
                    }>
                        <span style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {props.Data.includedServices!.map((mainGroup, mainIndex) => (
                                <IncServices
                                    key={"IncServices" + mainIndex}
                                    data={mainGroup}
                                    toShow={false}
                                    iconDataDefaultHeight={iconDataDefaultHeight}
                                    fullToolTip={true}
                                />
                            ))}
                        </span>
                    </Tooltip>
                </span>
                <>
                    {mealCodeArray?.map((iconD: any, iMeal: number) => (
                        <span key={iMeal} className="baggageServicesSpanStyle">
                            <Tooltip
                                title={
                                    <>
                                        <span>
                                            {SabreIconsEmoji()?.[iconD]?.hint !== undefined ? SabreIconsEmoji()?.[iconD]?.hint : iconD}
                                        </span>
                                    </>
                                }
                            >

                                <span>
                                    {iconD == "" || iconD == "N" ? null :
                                        <img src={`${SabreIconsEmoji()?.[getRightIcon(iconD)]?.icon}`}
                                            height={`${iconDataDefaultHeight}px`}
                                            alt={iconD}
                                        />
                                    }
                                </span>
                            </Tooltip>
                        </span>
                    ))}
                </>
            </div>
        </React.Fragment>
    );
};

interface ImageWithFallbackProps {
    src: string;      // URL of the main image
    alt: string;      // Alt text for the image
    fallback: string; // URL of the fallback image
}


export default FareHeaderIncludedServices;
interface FreeBaggeIndicatorProps {
    HasFreeBaggage: boolean;
}

const FreeBaggeIndicator: React.FC<FreeBaggeIndicatorProps> = ({
    HasFreeBaggage,
}) => {
    const title = HasFreeBaggage
        ? "This fare has free baggage allowance for at least one connection"
        : "This fare has no free baggage allowance.";
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    const elemRef = useRef<HTMLSpanElement>(null);

    return (
        <React.Fragment>
            <Popover target={elemRef} isOpen={open} toggle={toggle}>
                <PopoverBody>{title}</PopoverBody>
            </Popover>
            <span
                className="fa-layers fa-fw mb-1 mt-1"
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                onTouchStart={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                }}
                onTouchEnd={() => setOpen(false)}
                ref={elemRef}
            >
                <FontAwesomeIcon
                    style={{ opacity: HasFreeBaggage ? 1 : 0.5 }}
                    title={title}
                    icon={faLuggageCart}
                />
                <FontAwesomeIcon
                    style={
                        {
                            "--fa-secondary-opacity": "0.7",
                            "--fa-primary-color": "black",
                            color: `${HasFreeBaggage ? "green" : "red"}`,
                        } as CSSProperties
                    }
                    icon={
                        HasFreeBaggage ? faCheckCircle : faExclamationTriangle
                    }
                    // color={HasFreeBaggage ? "green" : "red"}
                    transform="shrink-7 down-6 right-5"
                />
            </span>
        </React.Fragment>
    );
};