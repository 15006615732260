import {
    faArrowLeft,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faCogs,
    faCalculator,
    faCreditCard,
    faFileInvoice,
    faPlane,
    faShoppingCart,
    faStar,
    faUsers,
    faChevronUp,
    faChevronDown,
} from "@fortawesome/pro-regular-svg-icons";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AssignablePassengerOption } from "components/AncillarySelection/AncillarySelection";
import { ServiceAddObj } from "components/Fare/AncillariesModal";
import {
    FarePriceOverviewCard,
    GetTotalFarePrice,
} from "components/Fare/FarePriceOverview";
import {
    ErrorDisplay,
    PaymentErrorDisplay,
} from "components/Shared/APIMetaDataDisplay";
import { ValidationErrorMessage } from "components/Shared/ErrorMessage";
import { GetNullableApiRequestDate } from "components/Shared/Helpers/DateConverters";
import { PriceDisplay } from "components/Shared/PriceDisplay";
import ValidIndicatorIcon from "components/Shared/ValidIndicatorIcon";
import { useAvailableFaresClient } from "hooks/useHttpClient";
import { useSessionHandler } from "hooks/useSessionHandler";
import { SetBookingPayload } from "Pages/AvailableFaresPage";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
    Alert,
    Collapse,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import {
    Session_Reducer_HideCustomerWaitScreen,
    Session_Reducer_HideGeneralWaitScreen,
    Session_Reducer_PushTransaction,
    Session_Reducer_SetWatiScreenInfo,
    Session_Reducer_ShowCustomerWaitScreen,
    Session_Reducer_ShowGeneralWaitScreen,
} from "rootExports/SessionReducer";
import {
    Adress,
    AgentBookFareConfiguration,
    AncillarySegmentBookRequest,
    AncillarySegmentIdentifier,
    AvailableFaresClient,
    BookingAddressBehavior,
    FareBookingInputPassengerModel,
    FareBookingLegIdentifier,
    FareBookingOnHoldEnum,
    FareBookingPassengerView,
    FareBookingSubmitModel,
    FareBookingTicketGroupOptions,
    PaymentBookingResponse,
    PaymentInstrumentResponseData,
    PreAuthResponseModel,
    ResponseModelPreAuth,
    SmartBookingApiResponse,
    SmartBookResponseDataModel,
} from "WebApiClient";
import FareBookingConfirmation from "../../BookingConfirmation/BookingConfirmation";
import { defaultAdress } from "../types/FareBookingTypes";
import FareBookingAddress from "./FareBooking.Address";
import FareBookingAncillaries from "./FareBooking.Ancillaries";
import FareBookingOptions from "./FareBooking.BookingOptions";
import FareBookingOther from "./FareBooking.Other";
import FareBookingPassengers from "./FareBooking.Passengers";
import FareBookingSelectedFlight from "./FareBooking.SelectedFlight";
import { useTranslation } from "react-i18next";
import {
    BookingValid,
    BookingValidResult,
    DefaultBookingValidResult,
} from "./FareBooking.Validate";
import { result } from "lodash";
import FareHeaderList from "components/AvailableFares/component/FareHeaderList";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import PaymentForm from "components/Payment/PaymentForm";
import { Focused } from "react-credit-cards-2";
import axios from "axios";
import { State } from "rootExports/rootReducer";
import { useUserInformation } from "hooks/various";
import i18next from "i18next";

interface PaymentFormState {
    cvc: string;
    expiry: string;
    focus: Focused | undefined;
    name: string;
    number: string;
}
export const FareBookingShell: React.FC<{
    Intit: SetBookingPayload | undefined;
    Discard: () => void;
    OnNewQuery: () => void;
    GoToFares: () => void;
    multiChannel?: any;
    OnModify: () => void;
    OnReset: any;
    IsModifySearch: any;
    OnSearchSubmitted: any;
    LastSubmitted: any;
    submitResult: any;
    GoToResults: any;
    Config: any;
    Init: any;
    fetching?: any;
    error?: any;
    response?: any;
    setExtraAncCheck?: (newValue: boolean) => void;
    extraAncCheck?: boolean;
}> = (props) => {
    const { t, i18n } = useTranslation();
    const multiChannal = props.multiChannel;
    const availFareClient = useAvailableFaresClient();
    const [config, setConfig] = useState<
        AgentBookFareConfiguration | undefined
    >(undefined);
    const [errorOccured, setErrorOccured] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        FetchConfig();
    }, []);

    function FetchConfig() {
        setLoading(true);
        availFareClient
            .getBookingConfiguration()
            .then((response) => {
                setConfig(response);
                setErrorOccured(false);
            })
            .catch(() => {
                setErrorOccured(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <div>
            {loading && (
                <div className="card-body">Loading Configuration...</div>
            )}
            {errorOccured && (
                <div className="card-body">
                    An error has occured while fetching configuration.
                </div>
            )}
            {config && props.Intit && (
                <FareBookingNew
                    GoToFares={props.GoToFares}
                    configuration={config}
                    init={props.Intit}
                    multiChannel={props.multiChannel}
                    OnNewQuery={props.OnNewQuery}
                    OnModify={props.OnModify}
                    OnReset={props.OnReset}
                    IsModifySearch={props.IsModifySearch}
                    OnSearchSubmitted={props.OnSearchSubmitted}
                    LastSubmitted={props.LastSubmitted}
                    submitResult={props.submitResult}
                    GoToResults={props.GoToResults}
                    Config={undefined}
                    Init={undefined}
                    fetching={props.fetching}
                    error={props.error}
                    response={props.response}
                    setExtraAncCheck={props.setExtraAncCheck}
                    extraAncCheck={props.extraAncCheck}
                />
            )}
        </div>
    );
};

const FareBookingNew: React.FC<{
    init: SetBookingPayload;
    configuration: AgentBookFareConfiguration;
    OnNewQuery: () => void;
    GoToFares: () => void;
    multiChannel: any;
    OnModify: () => void;
    OnReset: any;
    IsModifySearch: any;
    OnSearchSubmitted: any;
    LastSubmitted: any;
    submitResult: any;
    GoToResults: any;
    Config: any;
    Init: any;
    fetching?: any;
    error?: any;
    response?: any;
    setExtraAncCheck?: (newValue: boolean) => void;
    extraAncCheck?: boolean;
}> = (props) => {
    const { t } = useTranslation();
    const userInfo = useUserInformation();
    const multiChannel = props.multiChannel;
    const dispatch = useDispatch();
    const availFareClient = useAvailableFaresClient();
    const ENV = process.env.REACT_APP_ENVIRONMENT;
    const isTestOrDevEnvironment =
        process.env.REACT_APP_ENVIRONMENT !== "Production";
    const B2CSettings = useSelector(
        (state: State) => state.Session.FrontendSettings.paymentDataSettings
    );
    const BranchId = B2CSettings.length
        ? JSON.parse(B2CSettings).ID
        : userInfo.WebID;
    const fare = props.init.Fare;
    const [selectedConnections, setSelectedConnections] = useState<number[]>(
        props.init.SelectedConnections
    );
    const [errorOccured, setErrorOccured] = useState(false);
    const [bookingResponse, setBookingResponse] = useState<
        SmartBookingApiResponse | undefined
    >(undefined);
    const [paymentErrorResponse, setpaymentErrorResponse] = useState<
        PaymentBookingResponse | undefined
    >(undefined);
    const bookingResponseResult = bookingResponse?.result;
    const validationResult = bookingResponseResult?.validationResult;
    const bookingResult: SmartBookResponseDataModel | undefined =
        bookingResponseResult?.result;
    const [ticketGroupOptions, setticketGroupOptions] = useState<
        FareBookingTicketGroupOptions[]
    >(
        fare.ticketGroupOptions
            ? fare.ticketGroupOptions.map((val) => {
                const op: FareBookingTicketGroupOptions = {
                    ticketGroupID: val.ticketGroupID,
                    bookingOnHoldSelection:
                        FareBookingOnHoldEnum.NotApplicable,
                };
                return op;
            })
            : []
    );
    const [address, setAddress] = useState<Adress | undefined>(
        props.configuration.address!
    );

    // const [address, setAddress] = useState<Adress>(
    //     props.configuration.addressBehavior ===
    //         BookingAddressBehavior.EmptyUserInput
    //         ? defaultAdress
    //         : props.configuration.address!
    // );

    const [invoice, setInvoice] = useState<Adress>(
        // props.configuration.invoice == undefined ? defaultAdress : props.configuration.invoice!
        props.configuration.addressBehavior ===
            BookingAddressBehavior.EmptyUserInput
            ? defaultAdress
            : props.configuration.address!
    );

    const [webfarePassword, setWebfarePassword] = useState<string>(
        props.configuration.webfarePasswordBehavior ===
            BookingAddressBehavior.EmptyUserInput
            ? ""
            : props.configuration.webfarePassword!
    );
    const [emergencyPhone, setEmergencyPhone] = useState<string>(
        props.configuration.emergencyPhoneBehavior ===
            BookingAddressBehavior.EmptyUserInput
            ? ""
            : props.configuration.emergencyphone!
    );
    const [passengers, setPassengers] = useState<FareBookingPassengerView[]>(
        fare.bookingPassengers ? fare.bookingPassengers : []
    );
    const [reviewedChecked, setReviewedChecked] = useState(false);
    const sessionHandler = useSessionHandler();

    const [addedServices, setAddedServices] = useState<ServiceAddObj[]>(
        props.init.AddedServices
    );

    useEffect(() => {
        setAddedServices([]);
        // sendPageHeight1();
        sendPageHeight2();
    }, [
        props.init.Fare.identifier.fareIndex,
        props.init.Fare.identifier.fareResultID,
    ]);

    const sendPageHeight2 = () => {
        const height = document.body.scrollHeight;
        window.parent.postMessage(["setHeight", height + "px"], "*");
    };

    //dont contact me State
    const [dontContact, setDontContact] = useState(false);

    function toggleContact() {
        setDontContact((prev) => !prev);
    }

    //Terms and Conditions State
    const [termsCondition, setTermsCondition] = useState(false);
    function toggleTermsCondition() {
        setTermsCondition((prev) => !prev);
    }

    //isConfirmEmail
    const [isConfirmEmail, setIsConfirmEmail] = useState(false);
    //payment
    const [paymentBOFDetails, setPaymentBOFDetails] = useState<any>([]);
    const [paymentDataBackend, setPaymentDataBackend] = useState<[]>([]);
    //credit details
    const [paymentFormState, setPaymentFormState] = useState<PaymentFormState>({
        cvc: "",
        expiry: "",
        focus: undefined,
        name: "",
        number: "",
    });
    useEffect(() => {
        setSelectedConnections(props.init.SelectedConnections);
        sendPageHeight2();
    }, [props.init.SelectedConnections]);

    useEffect(() => {
        sendPageHeight2();
    }, []);

    function GetAssignablePassengers(): AssignablePassengerOption[] {
        let result: AssignablePassengerOption[] = [];

        passengers.forEach((element, index) => {
            const pData = element.bookingData;
            const useCustomLabel =
                (pData.firstName !== undefined && pData.firstName.length > 0) ||
                (pData.lastName !== undefined && pData.lastName.length > 0);
            const label = useCustomLabel
                ? `${pData.title ? pData.title : ""} ${pData.firstName ? pData.firstName : ""
                } ${pData.lastName ? pData.lastName : ""} (${pData.passengerType
                })`
                : `${pData.passengerType} ${element.passengerTypeIndex + 1}`;
            const option: AssignablePassengerOption = {
                Index: index,
                Label: label,
                PassengerType: pData.passengerType,
            };
            result.push(option);
        });
        return result;
    }

    const AssignablePassengers = GetAssignablePassengers();
    const [activeTab, setActiveTab] = useState(1);
    // const selectConnectionTabIndex = 1;
    const passengersTabIndex = 1;
    // const requiredInfoTabIndex = 2;
    const servicesTabIndex = 1;
    // const bookingOptionsTabIndex = 5;
    // const addressTabIndex = 6;
    const bookTabIndex = 1;
    const confirmationTabIndex = 1;
    const maxTab = bookingResult
        ? confirmationTabIndex
        : confirmationTabIndex - 1;
    //termsCondition redux
    const sessionInfo = useSelector((state: State) => state.Session);
    const TermsConditionLink = JSON.parse(
        sessionInfo.FrontendSettings.b2cSettingsText
    );

    //modal state for confirm booking
    const [expiredModalOpen, setExpiredModalOpen] = useState(false);
    function ToggleExpiredModal() {
        setExpiredModalOpen(!expiredModalOpen);
    }

    //error modal try again state
    const [finalBookErrorModal, setFinalBookErrorModal] = useState(true);

    function IncrementTab() {
        if (activeTab === passengersTabIndex && !ShowRequiredInfoTab) {
            setActiveTab(servicesTabIndex);
        } else if (activeTab === servicesTabIndex && !ShowBookingOptionsTab) {
            setActiveTab(bookTabIndex);
        } else {
            if (activeTab < maxTab) {
                setActiveTab(activeTab + 1);
            }
        }
    }

    function DecrementTab() {
        if (activeTab === servicesTabIndex && !ShowRequiredInfoTab) {
            setActiveTab(passengersTabIndex);
        } else if (activeTab === bookTabIndex && !ShowBookingOptionsTab) {
            setActiveTab(servicesTabIndex);
        } else {
            if (activeTab > 1) {
                setActiveTab(activeTab - 1);
            } else {
            }
        }
    }

    const toggle = (tab: number) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const nextTabDisabled = activeTab === maxTab;
    const prevTabDisabled = activeTab === 1;

    function OnSelectConnection(legIndex: number, connectionIndex: number) {
        let stateCopy = [...selectedConnections];
        stateCopy[legIndex] = connectionIndex;
        if (addedServices.length > 0) {
            setAddedServices([]);
            console.log(
                "Book page: Ancillaries were resettet because of connection change"
            );
        }
        setSelectedConnections(stateCopy);
        props.init.OnSelectConnection(legIndex, connectionIndex);
    }

    function OnBookingOnHoldSelectionChange(index: number, value: number) {
        let stateCopy = [...ticketGroupOptions];
        stateCopy[index].bookingOnHoldSelection = value;
        setticketGroupOptions(stateCopy);
    }

    const ValidationResult: BookingValidResult = fare
        ? BookingValid(
            paymentDataBackend,
            paymentBOFDetails,
            passengers,
            fare,
            address,
            invoice,
            props.configuration.addressBehavior,
            webfarePassword,
            emergencyPhone,
            props.configuration.webfarePasswordBehavior,
            props.configuration.emergencyPhoneBehavior,
            dontContact
        )
        : DefaultBookingValidResult;

    function OnUpdateWebfarePassword(val: string) {
        setWebfarePassword(val);
    }

    function OnUpdateEmergencyPhone(val: string) {
        setEmergencyPhone(val);
    }

    function OnUpdateAddress(val: Adress | any) {
        setAddress(val);
    }

    function OnUpdateInvoice(val: Adress) {
        setInvoice(val);
    }

    function OnupdatePaymentDetails(paymentData: []) {
        console.log(paymentData);
    }

    function OnPassengerUpdate(
        index: number,
        passenger: FareBookingPassengerView
    ) {
        let stateCopy = [...passengers];
        stateCopy[index] = passenger;
        setPassengers(stateCopy);
    }

    function GetSubmitModel(): FareBookingSubmitModel {
        const legIdentifiers: FareBookingLegIdentifier[] =
            selectedConnections.map((e, index) => {
                const result: FareBookingLegIdentifier = {
                    legIndex: index,
                    connectionIndex: e,
                };
                return result;
            });

        //mopdifying paymentBODdetails
        let modifiedPaymentBOFDetails = _.reduce(
            GetSubmitPassengers(),
            (acc: any, passenger: any, index) => {
                acc[`CTCE${index + 1}`] = passenger.email;
                acc[`CTCM${index + 1}`] = passenger.mobile;
                return acc;
            },
            {}
        );

        modifiedPaymentBOFDetails = {
            ...paymentBOFDetails,
            ...modifiedPaymentBOFDetails,
            CTCR: dontContact,
        };

        let model: FareBookingSubmitModel = {
            passengers: GetSubmitPassengers(),
            fareResultID: fare.identifier!.fareResultID,
            fareIndex: fare.fareIndex,
            legIdentifiers: legIdentifiers,
            ticketGroupOptions: ticketGroupOptions,
            adress: address == undefined ? invoice : address,
            otherInput: {
                webfarePassword: webfarePassword,
                emergencyPhone: emergencyPhone,
                paymentBOFDetails: Object.entries(modifiedPaymentBOFDetails)
                    .filter(([key]) => key !== "null")
                    .map(([key, value]) => ({ key, value })),
            },
            isLiveBooking: true,
            invoiceAdress: invoice,
            MultiChannelBranchGroup: multiChannel.branchgroup,
            MultiChannelBranch: multiChannel.branch,
            UsedRuleName: multiChannel.branch,
            DobHandling: 0,
        };
        return model;
    }

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [PaymentInstrumentResponseData, setPaymentInstrumentResponseData] =
        useState<PaymentInstrumentResponseData>();
    const [OrderIntentResponse, setOrderIntentResponse] = useState<any>();
    const [ip, setIp] = useState("");

    const totalFarePrice = GetTotalFarePrice(
        props.init.Fare.totalPrice,
        addedServices
    );

    useEffect(() => {
        if (!ip.length) {
            // fetchIP();
        }
    }, []);

    // const fetchIP = async () => {
    //   try {
    //     const response = await axios.get("https://api.ipify.org?format=json");
    //     setIp(response.data.ip);
    //   } catch (error) {
    //     console.error("Error fetching the IP address", error);
    //   }
    // };

    const OrderIntentRequestBody = {
        initialAmount: totalFarePrice.toFixed(2),
        currency: props.init.Fare.currency,
        async: {
            successUrl: "https://www.google.de/#newwindow=1&q=success",
            failureUrl: "https://www.google.de/#newwindow=1&q=failure",
            cancelUrl: "https://www.google.de/#newwindow=1&q=cancel",
            confirmUrl: "https://www.google.de/?newwindow=1&q=confirm",
            notifications: [
                {
                    notificationUrn: "urn:example:notification",
                    notificationEvent: ["EVENT_TYPE"],
                    notificationVersion: "1.0",
                },
            ],
        },
        transactionType: "PREAUTH",
        // "description": "Order description",
        // "statementDescription": "Statement description",
        // "basket": [
        //     {
        //         "name": "usbstick",
        //   "articleNumber": "sasdfas",
        //   "totalPrice": 2000,
        //   "totalPriceWithTax": 2000,
        //   "unitPrice": 2000,
        //   "unitPriceWithTax": 2000,
        //   "tax": 0,
        //   "quantity": 1
        //     }
        // ],
        ipAddress: ip == "" ? null : ip,
        // "channel": "MOTO",
        // "source": "Source123",
        allowedProducts: ["creditcard"],
        locale: "en-US",
    };
    const getPyementInstrument = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        dispatch(Session_Reducer_ShowCustomerWaitScreen());

        const cardDetails = {
            type: "creditcard",
            payment: {
                cardNumber: paymentFormState.number,
                verification: paymentFormState.cvc,
                expiryMonth: paymentFormState.expiry.split("/")[0],
                expiryYear: paymentFormState.expiry.split("/")[1],
                cardHolder: paymentFormState.name,
            },
        };

        try {
            const url =
                "http://localhost:5156/api/NexiPayment/payment-instruments";
            const response: PaymentInstrumentResponseData = await axios.post(
                url,
                cardDetails
            );
            console.log("payment----", response);
            setPaymentInstrumentResponseData(response);
            get3dsVersionInfo();
            setLoading(false);
        } catch (error) {
            console.error("Error processing payment:", error);
            setErrorMessage(
                "An error occurred while processing your payment. Please try again later."
            );
            setLoading(false);
        }
        dispatch(Session_Reducer_HideCustomerWaitScreen());
        dispatch(Session_Reducer_HideGeneralWaitScreen());
        sessionHandler.RefreshSession();
    };

    const get3dsVersionInfo = async () => {
        setLoading(true);
        dispatch(Session_Reducer_ShowCustomerWaitScreen());
        console.log(
            "PaymentInstrumentResponseData",
            PaymentInstrumentResponseData
        );

        const virsionInfo = {
            paymentInstrumentId:
                PaymentInstrumentResponseData?.data.paymentInstrumentId,
            cardAcquirerRoutingTo: "concardis",
        };

        try {
            const url =
                "http://localhost:5156/api/NexiPayment/Create3DSversionInfo";
            const response: PaymentInstrumentResponseData = await axios.post(
                url,
                virsionInfo
            );
            setPaymentInstrumentResponseData(response);
            setLoading(false);
        } catch (error) {
            console.error("Error processing payment:", error);
            setErrorMessage(
                "An error occurred while processing your payment. Please try again later."
            );
            setLoading(false);
        }

        dispatch(Session_Reducer_HideCustomerWaitScreen());
        dispatch(Session_Reducer_HideGeneralWaitScreen());
        sessionHandler.RefreshSession();
    };

    // const preAuth = async () => {
    //     if (paymentFormState.number !== '4111111111111111') {
    //         let request = {
    //             "initialAmount": hostname == 'localhost' || hostname == 'test' || hostname == 'dev' ? 0.01 : totalFarePrice,
    //             "currency": props.init.Fare.currency,
    //             "product": "creditcard",
    //             "async": {
    //                 "confirmUrl": "https://www.google.de/#newwindow=1&q=success",
    //                 "successUrl": "https://www.google.de/#newwindow=1&q=success",
    //                 "failureUrl": "https://www.google.de/#newwindow=1&q=success"
    //             },
    //             // "customer": "customer_0170ltuspf",
    //             // "billingAddress": null,
    //             "channel": "MOTO",
    //             "payment": {
    //                 "cardNumber": paymentFormState.number,
    //                 "verification": paymentFormState.cvc,
    //                 "expiryMonth": paymentFormState.expiry.split("/")[0],
    //                 "expiryYear": paymentFormState.expiry.split("/")[1],
    //                 "cardHolder": paymentFormState.name
    //             },
    //             "BranchId": BranchId,
    //             "BranchName": userInfo.BranchName
    //             // "deviceIdent": {
    //             //   "deviceIdentToken": "gerät123"
    //             // },
    //             // "tracking": [
    //             //   {
    //             //     "trackingId": "verfolgung123",
    //             //     "provider": "DPD"
    //             //   }
    //             // ],
    //             // "risk": {
    //             //   "customerAccount": {
    //             //     "accountIdentifier": "customer-abc123",
    //             //     "creationDate": "2024-05-10",
    //             //     "lastChangeDate": "2024-05-10",
    //             //     "changeIndicator": "CHANGED_WITH_THIS_TRANSACTION",
    //             //     "lastPasswordChangeDate": "2024-05-10",
    //             //     "passwordChangeIndicator": "LESS_THAN_THIRTY_DAYS",
    //             //     "authenticationMethod": "OWN_CREDENTIALS",
    //             //     "authenticationTimestamp": "2024-05-10T15:04:09.722Z",
    //             //     "shippingAddressUsageIndicator": "LESS_THAN_THIRTY_DAYS",
    //             //     "shippingAddressFirstUsage": "2024-05-10",
    //             //     "transactionCountLastDay": 1,
    //             //     "transactionCountLastYear": 10,
    //             //     "orderCountLast6Months": 5,
    //             //     "suspiciousActivity": false,
    //             //     "accountEqualsShippingName": true,
    //             //     "paymentAccountAgeIndicator": "NO_ACCOUNT",
    //             //     "paymentAccountEnrollementDate": "2024-05-10"
    //             //   },
    //             //   "shippingInfo": {
    //             //     "shippingEqualsBillingAddress": true,
    //             //     "shippingIndicator": "SHIP_TO_BILLING_ADDRESS",
    //             //     "deliveryTime": "SAME_DAY",
    //             //     "deliveryEmail": "john@doe.com"
    //             //   },
    //             //   "orderInfo": {
    //             //     "isReorder": false,
    //             //     "preOrderIndicator": "MERCHANTDISE_AVAILABLE",
    //             //     "preOrderDate": "2024-05-10",
    //             //     "orderType": "01"
    //             //   }
    //             // }
    //         }
    //         dispatch(Session_Reducer_ShowCustomerWaitScreen());
    //         dispatch(Session_Reducer_ShowGeneralWaitScreen());
    //         dispatch(Session_Reducer_SetWatiScreenInfo({ Label: "", Percentage: 50 }));
    //         availFareClient.PreAuth(request).then((response) => {
    //             const result = JSON.parse(response);
    //             console.log("preAuth---", result);
    //             setOrderIntentResponse(result);
    //             if (typeof (result.status) == "string" && result.orderId.length) {
    //                 paymentBOFDetails["PO"] = result.orderId;
    //                 OnSubmit();
    //             } else {
    //                 ToggleExpiredModal()
    //                 setErrorOccured(true);
    //                 dispatch(Session_Reducer_HideCustomerWaitScreen());
    //                 dispatch(Session_Reducer_HideGeneralWaitScreen());
    //             }
    //         }
    //         ).catch(() => {
    //             ToggleExpiredModal()
    //             setErrorOccured(true);
    //             dispatch(Session_Reducer_HideCustomerWaitScreen());
    //             dispatch(Session_Reducer_HideGeneralWaitScreen());
    //         }).finally(() => {
    //             sessionHandler.RefreshSession();
    //         });
    //     } else {
    //         OnSubmit();
    //     }
    // }

    const preAuth = async () => {
        const { number, cvc, expiry, name } = paymentFormState;
        const { currency } = props.init.Fare;
        const initialAmount = isTestOrDevEnvironment ? 0.01 : totalFarePrice;
        if (isTestOrDevEnvironment && number !== "4111111111111111") {
            const request = {
                initialAmount: initialAmount,
                currency: currency,
                product: "creditcard",
                async: {
                    confirmUrl: "https://www.google.de/#newwindow=1&q=success",
                    successUrl: "https://www.google.de/#newwindow=1&q=success",
                    failureUrl: "https://www.google.de/#newwindow=1&q=success",
                },
                channel: "MOTO",
                payment: {
                    cardNumber: number,
                    verification: cvc,
                    expiryMonth: expiry.split("/")[0],
                    expiryYear: expiry.split("/")[1],
                    cardHolder: name,
                },
                WebId: userInfo.WebID,
            };
            try {
                dispatch(Session_Reducer_ShowCustomerWaitScreen());
                dispatch(Session_Reducer_ShowGeneralWaitScreen());
                dispatch(
                    Session_Reducer_SetWatiScreenInfo({
                        Label: "",
                        Percentage: 50,
                    })
                );
                const response = await availFareClient.PreAuth(request);
                const result = JSON.parse(response);

                setOrderIntentResponse(result);
                if (
                    typeof result.status === "string" &&
                    result.orderId.length
                ) {
                    paymentBOFDetails["PO"] = result.orderId;
                    OnSubmit();
                } else {
                    setpaymentErrorResponse(result);
                    throw new Error(result.errors[0].message);
                }
            } catch (error) {
                console.error("preAuth error", error);
                ToggleExpiredModal();
                setErrorOccured(true);
            } finally {
                dispatch(Session_Reducer_HideCustomerWaitScreen());
                dispatch(Session_Reducer_HideGeneralWaitScreen());
                sessionHandler.RefreshSession();
            }
        } else {
            OnSubmit();
        }
    };

    const createOrderIntent = async () => {
        dispatch(Session_Reducer_ShowCustomerWaitScreen());
        dispatch(Session_Reducer_ShowGeneralWaitScreen());
        dispatch(
            Session_Reducer_SetWatiScreenInfo({ Label: "", Percentage: 50 })
        );
        availFareClient
            .getOrderId(OrderIntentRequestBody)
            .then((response) => {
                const result = JSON.parse(response);
                setOrderIntentResponse(result);
                if (typeof result.status == "string" && result.orderId.length) {
                    paymentBOFDetails["PO"] = result.orderId;
                    OnSubmit();
                } else {
                    setErrorOccured(true);
                }
            })
            .catch(() => {
                setErrorOccured(true);
                dispatch(Session_Reducer_HideCustomerWaitScreen());
                dispatch(Session_Reducer_HideGeneralWaitScreen());
            })
            .finally(() => {
                sessionHandler.RefreshSession();
            });
    };

    function OnSubmit() {
        dispatch(Session_Reducer_ShowCustomerWaitScreen());
        dispatch(Session_Reducer_ShowGeneralWaitScreen());
        dispatch(
            Session_Reducer_SetWatiScreenInfo({
                Label: "Booking selected flight...",
                Percentage: 50,
            })
        );

        window.scrollTo(0, 0);
        const submitModel = GetSubmitModel();
        const newSubmitModel = { ...submitModel };
        newSubmitModel.passengers.map(
            (i) => (i.firstName = i?.firstName + " " + i.middleName)
        );
        availFareClient
            .submitBooking(submitModel)
            .then((response) => {
                dispatch(
                    Session_Reducer_PushTransaction(response.responseMetaData)
                );
                setBookingResponse(response);
                const result = response.result;
                if (!result) {
                    setErrorOccured(true);
                    setActiveTab(bookTabIndex);
                } else {
                    if (
                        result.validationResult.isValid &&
                        result.result === undefined
                    ) {
                        setErrorOccured(true);
                        setActiveTab(bookTabIndex);
                    } else {
                        if (!result.validationResult.isValid) {
                            setErrorOccured(false);
                            setFinalBookErrorModal(false);
                            setActiveTab(bookTabIndex);
                        } else {
                            setErrorOccured(false);
                            setFinalBookErrorModal(false);
                            setActiveTab(confirmationTabIndex);
                        }
                    }
                }
                ToggleExpiredModal();
            })
            .catch(() => {
                setErrorOccured(true);
                ToggleExpiredModal();
            })
            .finally(() => {
                dispatch(Session_Reducer_HideCustomerWaitScreen());
                dispatch(Session_Reducer_HideGeneralWaitScreen());
                sessionHandler.RefreshSession();
            });
    }

    useEffect(() => {
        setFinalBookErrorModal(true);
    }, [props?.init?.Fare?.fareIndex]);

    const ShowEmergencyPhone =
        props.configuration.emergencyPhoneBehavior !==
        BookingAddressBehavior.UseBranchAdress;
    const ShowWebfarePassword = ValidationResult.Other.WebfarePasswordRequired;
    const ShowRequiredInfoTab = ShowEmergencyPhone || ShowWebfarePassword;

    function DetectBookingOptionsPossible(): boolean {
        let result = false;
        fare.ticketGroupOptions.forEach((t) => {
            if (t.bookOnHoldOptions!.bookOnHoldPossible) {
                result = true;
            }
        });
        return result;
    }

    const ShowBookingOptionsTab = DetectBookingOptionsPossible();
    const selectConnectionIcon = <FontAwesomeIcon icon={faPlane} />;
    const renderSelectedFlight = (
        <FareBookingSelectedFlight
            Fare={props.init.Fare}
            SelectedConnections={selectedConnections}
            OnSelectConnection={OnSelectConnection}
            Icon={selectConnectionIcon}
            Passengers={AssignablePassengers}
            AddedServices={addedServices}
        />
    );

    const passengersIcon = (
        <ValidIndicatorIcon
            IsValid={
                ValidationResult.PassengersValid
                // ValidationResult.Other.paymentBOFDetails
            }
            Icon={faUsers}
            NoValueSet={false}
        />
    );

    const renderPassengers = (
        <FareBookingPassengers
            Passengers={passengers}
            ValidationResult={ValidationResult}
            OnPassengerUpdate={OnPassengerUpdate}
            OnPaymentDetails={OnupdatePaymentDetails}
            Apisrequirements={fare.apisRequirenments!}
            Icon={passengersIcon}
            setPaymentBOFDetails={setPaymentBOFDetails}
            paymentBOFDetails={paymentBOFDetails}
            setPaymentDataBackend={setPaymentDataBackend}
            paymentValid={ValidationResult.Other.paymentBOFDetails}
            setIsConfirmEmail={setIsConfirmEmail}
            Fare={props.init.Fare}
            dontContact={dontContact}
            reviewedChecked={reviewedChecked}
        />
    );

    const otherIcon = (
        <ValidIndicatorIcon
            IsValid={ValidationResult.Other.Valid}
            Icon={faFileInvoice}
            NoValueSet={false}
        />
    );

    const renderServices = (
        <FareBookingAncillaries
            Fare={props.init.Fare}
            SelectedConnections={selectedConnections}
            SetSelectedConnection={OnSelectConnection}
            AddedServices={addedServices}
            SetAddedServices={(service: ServiceAddObj[]) => {
                setAddedServices(service);
            }}
            Passengers={AssignablePassengers}
            fetching={props.fetching}
            error={props.error}
            response={props.response}
            setExtraAncCheck={props.setExtraAncCheck}
        />
    );

    const servicesIcon = (
        <ValidIndicatorIcon IsValid={true} Icon={faStar} NoValueSet={false} />
    );

    const renderOther1 = (
        <FareBookingOther
            WebfarePassword={webfarePassword}
            WebfarePasswordBehavior={
                props.configuration.webfarePasswordBehavior
            }
            EmergencyPhone={emergencyPhone}
            EmergencyPhoneBehavior={props.configuration.emergencyPhoneBehavior}
            OnUpdateWebfarePassword={OnUpdateWebfarePassword}
            OnUpdateEmergencyPhone={OnUpdateEmergencyPhone}
            IsValid={ValidationResult.Other}
            Icon={otherIcon}
            IsOpen={false}
        />
    );
    const renderOther2 = (
        <FareBookingOther
            WebfarePassword={webfarePassword}
            WebfarePasswordBehavior={
                props.configuration.webfarePasswordBehavior
            }
            EmergencyPhone={emergencyPhone}
            EmergencyPhoneBehavior={props.configuration.emergencyPhoneBehavior}
            OnUpdateWebfarePassword={OnUpdateWebfarePassword}
            OnUpdateEmergencyPhone={OnUpdateEmergencyPhone}
            IsValid={ValidationResult.Other}
            Icon={otherIcon}
            IsOpen={true}
        />
    );

    const bookingOptionsIcon = <FontAwesomeIcon icon={faCogs} />;
    const renderBookingOptions = (
        <FareBookingOptions
            AvailableTicketGroupOptions={fare.ticketGroupOptions!}
            SelectedticketGroupOptions={ticketGroupOptions}
            OnBookingOnHoldSelectionChange={OnBookingOnHoldSelectionChange}
            Icon={bookingOptionsIcon}
        />
    );

    const adressIcon = (
        <ValidIndicatorIcon
            // IsValid={ValidationResult.Address.Valid && ValidationResult.Invoice.Valid}
            IsValid={true}
            Icon={faFileInvoice}
            NoValueSet={false}
        />
    );

    const originalText = t("FareBooking.Check&BookPage.PaymentNotification");
    const replacementWord = ENV;

    // Split the text at the placeholder
    const newText = originalText
        .replace(
            "{ }",
            `<span style="font-weight: 700">'${replacementWord}'</span>`
        )
        .replace(
            `${i18next.language == "de" ? "0,01 €" : "€0.01"}`,
            `<span style = "font-weight: 700" > ${i18next.language == "de" ? "0,01 €" : "€0.01"
            } </span> `
        );

    const createMarkup = (html: string) => {
        return { __html: html };
    };

    const renderAdress1 = (
        <FareBookingAddress
            Passengers={passengers}
            Address={address}
            OnUpdateAddress={OnUpdateAddress}
            // AdressValid={ValidationResult.Address}
            AdressValid={{
                Valid: true,
                Title: true,
                FirstName: true,
                LastName: true,
                Street: true,
                Zip: true,
                City: true,
                Country: true,
            }}
            AddressBehavior={props.configuration.addressBehavior}
            Icon={adressIcon}
            Invoice={invoice}
            OnUpdateInvoice={OnUpdateInvoice}
            // InvoiceValid={ValidationResult.Invoice}
            InvoiceValid={{
                Valid: true,
                Title: true,
                FirstName: true,
                LastName: true,
                Street: true,
                Zip: true,
                City: true,
                Country: true,
            }}
            IsOpen={true}
        />
    );

    const renderAdress2 = (
        <FareBookingAddress
            Address={address}
            OnUpdateAddress={OnUpdateAddress}
            // AdressValid={ValidationResult.Address}
            AdressValid={{
                Valid: true,
                Title: true,
                FirstName: true,
                LastName: true,
                Street: true,
                Zip: true,
                City: true,
                Country: true,
            }}
            AddressBehavior={props.configuration.addressBehavior}
            Icon={adressIcon}
            Invoice={invoice}
            OnUpdateInvoice={OnUpdateInvoice}
            // InvoiceValid={ValidationResult.Invoice}
            InvoiceValid={{
                Valid: true,
                Title: true,
                FirstName: true,
                LastName: true,
                Street: true,
                Zip: true,
                City: true,
                Country: true,
            }}
            IsOpen={false}
        />
    );

    const checkAndSubmitIcon = (
        <ValidIndicatorIcon
            IsValid={
                ValidationResult.Valid &&
                ValidationResult.Other.paymentBOFDetails
            }
            Icon={faShoppingCart}
            NoValueSet={false}
        />
    );

    function GetSubmitPassengers(): FareBookingInputPassengerModel[] {
        let result: FareBookingInputPassengerModel[] = [];
        passengers.forEach((passenger, index) => {
            const services = GetAncillaryForPassenger(passenger, index);
            result.push({
                ...passenger.bookingData,
                ancillaries: services,
                optionalDateOfBirth: GetNullableApiRequestDate(
                    passenger.bookingData.dateOfBirth
                ),
                dateOfBirth: undefined,
            });
        });
        return result;
    }

    function GetAncillaryForPassenger(
        passenger: FareBookingPassengerView,
        index: number
    ): AncillarySegmentBookRequest[] {
        let result: AncillarySegmentBookRequest[] = [];
        const legPassengerServices: ServiceAddObj[] = addedServices.filter(
            (e) => e.PassengerIndex === index
        );
        legPassengerServices.forEach((addedService) => {
            let segments: AncillarySegmentIdentifier[] = [];
            addedService.Segments.forEach((segment) => {
                const requestSegment: AncillarySegmentIdentifier = {
                    arrival: segment.arrival,
                    departure: segment.departure,
                };
                segments.push(requestSegment);
            });
            const serviceAdd: AncillarySegmentBookRequest = {
                bookingCode: addedService.Service.bookingCode,
                bookingType: addedService.Service.bookingType,
                carrierCode: addedService.Service.serviceCarrier,
                date: undefined,
                location: addedService.Service.serviceLocation,
                segments: segments,
                text: addedService.Service.text,
                id: addedService.Service.id,
                extensions: addedService.Service.extensions,
                serviceType: addedService.Service.serviceType,
            };
            result.push(serviceAdd);
        });
        return result;
    }

    function OnModify() {
        props.OnModify();
    }

    const [paymentValidation, setPaymentValidation] = useState(false);
    const [showPaymentCollapse, setShowPaymentCollapse] =
        useState<boolean>(true);

    const sendPageHeight1 = () => {
        const height = 800;
        console.log("payment_", height);
        window.parent.postMessage(["setHeight", height + "px"], "*");
    };

    const sendPageHeight = () => {
        setTimeout(() => {
            const height = document.body.scrollHeight;
            console.log("payment_", height);
            window.parent.postMessage(["setHeight", height + "px"], "*");
        }, 500);
    };

    return (
        <Fragment>
            <div className="card">
                <div className="fareBookHeader">
                    <FareHeaderList
                        OnReset={props.OnReset}
                        IsModifySearch={props.IsModifySearch}
                        OnSearchSubmitted={props.OnSearchSubmitted}
                        LastSubmitted={props.LastSubmitted}
                        submitResult={props.submitResult}
                        GoToResults={props.GoToResults}
                        Config={undefined}
                        Init={undefined}
                        showSearchForm={false}
                        OnModify={props.OnModify}
                        language={""}
                    />
                </div>
                <div
                    className="card-body mt-3 fareBookingCard"
                    style={{ margin: "0px 55px" }}
                >
                    <TabContent activeTab={activeTab} className="mt-2">
                        {true && (
                            <div>
                                {renderSelectedFlight}
                                {renderPassengers}
                                {props.extraAncCheck && renderServices}
                                {renderAdress1}
                                <div
                                    className={
                                        props.extraAncCheck
                                            ? "card mt-3 mx-2"
                                            : "card mx-2 mt-3"
                                    }
                                >
                                    <div
                                        className="blueCard card-header card-header-primary d-flex justify-content-between cursor-pointer"
                                        onClick={() => {
                                            setShowPaymentCollapse(
                                                !showPaymentCollapse
                                            );
                                            sendPageHeight1();
                                            sendPageHeight();
                                        }}
                                    >
                                        {/* {t("FareBooking.Check&BookPage.Check/Book")} */}
                                        <span>
                                            {/* <FontAwesomeIcon icon={faCreditCard} />{" "} */}
                                            {!paymentValidation ? (
                                                <ValidIndicatorIcon
                                                    IsValid={paymentValidation}
                                                    Icon={faCreditCard}
                                                    NoValueSet={false}
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faCreditCard}
                                                />
                                            )}{" "}
                                            {t(
                                                "FareBooking.Check&BookPage.Payment"
                                            )}
                                        </span>
                                        <FontAwesomeIcon
                                            icon={
                                                showPaymentCollapse
                                                    ? faChevronUp
                                                    : faChevronDown
                                            }
                                        />
                                    </div>

                                    <Collapse isOpen={showPaymentCollapse}>
                                        {/* <div className="d-flex calPaymentContainer"> */}

                                        {isTestOrDevEnvironment && (
                                            <div className="col-6 mb-2 mx-auto text-center">
                                                <Alert color="danger">
                                                    <p
                                                        className="mb-0"
                                                        dangerouslySetInnerHTML={createMarkup(
                                                            newText
                                                        )}
                                                    />
                                                </Alert>
                                            </div>
                                        )}

                                        <div className="row calculationPaymentContainer">
                                            <div className="col-md-6 col-lg-4">
                                                <FarePriceOverviewCard
                                                    AssignablePassengers={
                                                        AssignablePassengers
                                                    }
                                                    Currency={
                                                        props.init.Fare
                                                            .currency!
                                                    }
                                                    FarePrice={
                                                        props.init.Fare
                                                            .totalPrice
                                                    }
                                                    Services={addedServices}
                                                    TotalTax={
                                                        props.init.Fare.totalTax
                                                    }
                                                    FullSegments={
                                                        props?.response?.result
                                                            ?.segments
                                                    }
                                                    cardViewPayment={true}
                                                    fareData={
                                                        props.init.Fare
                                                            .calculationResult
                                                            .calculationsByPassengerType
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6 col-lg-8">
                                                <PaymentForm
                                                    setPaymentFormState={
                                                        setPaymentFormState
                                                    }
                                                    paymentFormState={
                                                        paymentFormState
                                                    }
                                                    setPaymentValidation={
                                                        setPaymentValidation
                                                    }
                                                    firstPassengerName={GetSubmitPassengers()}
                                                    reviewedChecked={reviewedChecked}
                                                />
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="row mb-2 mt-3">
                                    <div className="col-12">
                                        <div className="card">
                                            {/* <div className="card-header card-header-primary">
                        {t("FareBooking.Check&BookPage.Check/Book")}
                      </div> */}
                                            <div className="card-body card-body-primary">
                                                <div className="row">
                                                    {(!ValidationResult.Valid ||
                                                        !ValidationResult.Other
                                                            .paymentBOFDetails ||
                                                        isConfirmEmail ||
                                                        !paymentValidation) && (
                                                            <div className="col-12 mb-2">
                                                                <Alert color="danger">
                                                                    {t(
                                                                        "FareBooking.Check&BookPage.DangerText"
                                                                    )}
                                                                </Alert>
                                                            </div>
                                                        )}

                                                    {validationResult && (
                                                        <div className="col-12 mb-2">
                                                            <ValidationErrorMessage
                                                                error={
                                                                    !validationResult.isValid
                                                                }
                                                                messages={
                                                                    validationResult.errorSummary
                                                                }
                                                            />
                                                        </div>
                                                    )}

                                                    <div className="col-12 mb-2">
                                                        <div
                                                            className="form-check"
                                                            onClick={() => {
                                                                setReviewedChecked(
                                                                    !reviewedChecked
                                                                );
                                                            }}
                                                        >
                                                            <input
                                                                checked={
                                                                    reviewedChecked
                                                                }
                                                                onChange={() => { }}
                                                                type="checkbox"
                                                                className="form-check-input cursor-pointer"
                                                            />
                                                            <label className="form-check-label cursor-pointer">
                                                                {t(
                                                                    "FareBooking.Check&BookPage.ReviewText"
                                                                )}
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                checked={
                                                                    termsCondition
                                                                }
                                                                type="checkbox"
                                                                className="form-check-input cursor-pointer"
                                                                onChange={
                                                                    toggleTermsCondition
                                                                }
                                                            />

                                                            <label className="form-check-label cursor-pointer">
                                                                {/* <span
                                                                    onClick={
                                                                        toggleTermsCondition
                                                                    }
                                                                >
                                                                    {t(
                                                                        "FareBooking.Check&BookPage.AgreeText"
                                                                    )}{" "}
                                                                </span> */}
                                                                <a
                                                                    style={{
                                                                        textDecoration:
                                                                            "none",
                                                                    }}
                                                                    rel="noreferrer noopener"
                                                                    href={
                                                                        TermsConditionLink?.TermsConditionsLink
                                                                    }
                                                                    target="_blank"
                                                                >
                                                                    {t(
                                                                        "FareBooking.Check&BookPage.TermsAndConditionText"
                                                                    )}
                                                                </a>
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                checked={
                                                                    dontContact
                                                                }
                                                                type="checkbox"
                                                                className="form-check-input cursor-pointer"
                                                                onChange={
                                                                    toggleContact
                                                                }
                                                            />
                                                            <label
                                                                className="form-check-label cursor-pointer"
                                                                onClick={
                                                                    toggleContact
                                                                }
                                                            >
                                                                {t(
                                                                    "FareBooking.Check&BookPage.NoContactText"
                                                                )}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-2">
                                                        <div className="d-grid">
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={
                                                                    preAuth
                                                                }
                                                                // onClick={getPyementInstrument}
                                                                // onClick={OnSubmit}
                                                                disabled={
                                                                    !ValidationResult.Valid ||
                                                                    !reviewedChecked ||
                                                                    // !ValidationResult.Other.paymentBOFDetails ||
                                                                    !paymentValidation ||
                                                                    isConfirmEmail ||
                                                                    !termsCondition ||
                                                                    !finalBookErrorModal
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faShoppingCart
                                                                    }
                                                                />{" "}
                                                                {t(
                                                                    "FareBooking.Check&BookPage.BookNow"
                                                                )}{" "}
                                                                <PriceDisplay
                                                                    currency={
                                                                        props
                                                                            .init
                                                                            .Fare
                                                                            .currency!
                                                                    }
                                                                    price={
                                                                        totalFarePrice
                                                                    }
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {bookingResult && bookingResponse?.result?.validationResult?.isValid ? (
                            <Modal
                                className="confirmationError"
                                centered
                                isOpen={expiredModalOpen}
                                toggle={ToggleExpiredModal}
                            >
                                <ModalHeader toggle={ToggleExpiredModal}>
                                    {bookingResponse?.responseMetaData.errorOccured ? t("FareBooking.Check&BookPage.Error") : t("FareBooking.Check&BookPage.Confirmation")}
                                </ModalHeader>
                                <ModalBody>
                                    <FareBookingConfirmation
                                        Result={bookingResult}
                                        ResultStatus={
                                            bookingResponse?.responseMetaData.errorOccured
                                        }
                                        OnNewQuery={props.OnNewQuery}
                                        passengers={passengers}
                                        ErrorData={
                                            bookingResponse?.responseMetaData
                                        }
                                        errorOccured={errorOccured}
                                    />
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        onClick={() => {
                                            ToggleExpiredModal();
                                            if (!bookingResponse?.responseMetaData
                                                .errorOccured) {
                                                props.OnNewQuery();
                                            }
                                        }}
                                        color="primary">
                                        {bookingResponse?.responseMetaData
                                            .errorOccured ? "Try again" : "Ok"}
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        ) : (
                            <>
                                <Modal
                                    className="confirmationError"
                                    centered
                                    isOpen={expiredModalOpen}
                                    toggle={ToggleExpiredModal}
                                >
                                    <ModalHeader toggle={ToggleExpiredModal}>
                                        {errorOccured
                                            ? ""
                                            : t(
                                                "FareBooking.Check&BookPage.Error"
                                            )}
                                    </ModalHeader>
                                    <ModalBody>
                                        {!bookingResponse?.result?.validationResult?.isValid ?
                                            <ValidationErrorMessage
                                                error={
                                                    !validationResult?.isValid
                                                }
                                                messages={
                                                    validationResult?.errorSummary
                                                }
                                            />
                                            :
                                            <>
                                                {paymentErrorResponse?.errors && (
                                                    <PaymentErrorDisplay
                                                        data={
                                                            paymentErrorResponse?.errors
                                                        }
                                                    />
                                                )}
                                                {bookingResponse
                                                    ?.responseMetaData
                                                    .errorOccured && (
                                                        <ErrorDisplay
                                                            data={
                                                                bookingResponse?.responseMetaData
                                                            }
                                                            isHide={true}
                                                        />
                                                    )}
                                            </>
                                        }
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            onClick={() => {
                                                ToggleExpiredModal();
                                            }}
                                            color="primary"
                                        >
                                            {errorOccured ? "Try again" : "Ok"}
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            </>
                        )}
                    </TabContent>
                </div>
            </div>
            <div
                className="farePrevBtn"
                style={{ position: "fixed", top: "20vh", left: "0px" }}>
                <div className="btn-group btn-group-vertical">
                    <button
                        style={{ borderRadius: "0px 8px 8px 0px" }}
                        className="btn btn-primary btn-lg mb-2 py-4 PrevBtn"
                        onClick={
                            prevTabDisabled ? props.GoToFares : DecrementTab
                        }
                        disabled={true ? false : prevTabDisabled}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                </div>
            </div>
        </Fragment>
    );
};
