import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BaseApiResponseMetaData } from "WebApiClient";

interface ImportBookingModalProps {
  Close: () => void
  IsOpen: boolean
  data: BaseApiResponseMetaData | undefined

}
const BookingManagerErrorModal: React.FC<ImportBookingModalProps> = props => {


  return (
    <React.Fragment>

      <Modal size="lg" centered toggle={props.Close} isOpen={props.IsOpen}>
        <ModalHeader toggle={props.Close}>Something went wrong</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12">

              <ErrorDisplay isHide={true} data={props.data} />
            </div>

          </div>

        </ModalBody>

        <ModalFooter>

          <Button color="primary" onClick={props.Close}>close</Button>
        </ModalFooter>


      </Modal>

    </React.Fragment>
  );


}














export default BookingManagerErrorModal



