import { faMedium } from "@fortawesome/free-brands-svg-icons";
import {
    faChalkboardTeacher,
    faCircle,
    faCoffee,
    faCookieBite,
    faCouch,
    faExchange,
    faFastForward,
    faGavel,
    faGlassMartini,
    faInfoCircle,
    faMapMarkerQuestion,
    faMapSigns,
    faMoneyCheckEditAlt,
    faPaw,
    faPlug,
    faRandom,
    faRedo,
    faShoppingBag,
    faSuitcase,
    faTachometerFastest,
    faTicketAlt,
    faTv,
    faUndoAlt,
    faUserCheck,
    faUtensils,
    faWifi,
    IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import _ from "lodash";
import {
    IncludedServiceData,
    IncludedServiceMainType,
    IncludedServiceSubType,
} from "WebApiClient";
import Tooltip from "@mui/material/Tooltip";
import { SabreIconsEmoji } from "components/Fare/IncludedServiceIcons/SabreIcons";

interface IncludedServiceIconProps {
    data: any;
    incSerFull?: any;
    toShow?: boolean;
    checkSabreOther?: any;
    iconDataDefaultHeight: number;
    fullToolTip: boolean;
}
var warningCheck = false;

export const incServices: React.FC<IncludedServiceIconProps> = (props) => {
    const iconDataDefaultHeight = props.iconDataDefaultHeight;
    var mainGroup: any = props.data;
    const uniqueServices = _.uniqBy(mainGroup.services, "hint");
    const resultObject = { ...mainGroup, services: uniqueServices };

    function checkSabreServiceIcon() {
        // Get the dynamic icons from the SabreIconsEmoji function
        const sabreIcons = SabreIconsEmoji();

        switch (props.data.type) {
            case 0:
                return <img
                    src={`${sabreIcons['Carry-on baggage']?.icon}`}
                    height={`${iconDataDefaultHeight}px`}
                    alt="hitchhiker"
                />
            case 1:
                return <img src={`${sabreIcons.Seat?.icon}`}
                    height={`${iconDataDefaultHeight}px`}
                    alt="hitchhiker"
                />
            case 2:
                return <img
                    src={`${sabreIcons.M?.icon}`}
                    height={`${iconDataDefaultHeight}px`}
                    alt="hitchhiker"
                />
            case 3:
                return <img
                    src={`${sabreIcons.MileageAccural?.icon}`}
                    height={`${iconDataDefaultHeight}px`}
                    alt="hitchhiker"
                />
            case 5:
                return <img
                    src={`${sabreIcons.InFlightEntertainment?.icon}`}
                    height={`${iconDataDefaultHeight}px`}
                    alt="hitchhiker"
                />
            case 6:
                return <img
                    src={`${sabreIcons.Change?.icon}`}
                    height={`${iconDataDefaultHeight}px`}
                    alt="hitchhiker"
                />
            default:
                return (
                    <FontAwesomeIcon
                        icon={GetIcon(mainGroup)}
                        style={{ marginRight: "4px" }}
                    />
                );
        }
    }
    const warningCheckFalse = (hint: string) => {
        warningCheck = false
        return hint
    }
    
    return (
        <React.Fragment>
            <span className="baggageServicesSpanStyle"
            >
                {
                    props.fullToolTip == false ? <Tooltip
                        title={
                            <React.Fragment>
                                {mainGroup.services!.map(
                                    (sub: any, subIndex: any) => (
                                        <span key={"mainSer_" + subIndex}>
                                            <li>{sub.hint}</li>
                                        </span>
                                    )
                                )}
                            </React.Fragment>
                        }
                    >
                        <span>
                            {resultObject.services!.map((sub: any, Index: any) => (
                                <>
                                    {
                                        Index == 0 && <span style={{ marginRight: "4px" }}>
                                            {typeof sub.hint === "string" &&
                                                sub.hint.includes("WARNING:") && props?.toShow
                                                ? null : checkSabreServiceIcon()}
                                        </span>
                                    }
                                </>

                            ))}
                        </span>
                    </Tooltip> :
                        <span>
                            {resultObject.services!.map((sub: any, Index: any) => (
                                <>
                                    {
                                        Index == 0 && <span style={{ marginRight: "4px" }}>
                                            {typeof sub.hint === "string" &&
                                                sub.hint.includes("WARNING:") && props?.toShow
                                                ? null : checkSabreServiceIcon()}
                                        </span>
                                    }
                                </>

                            ))}
                        </span>
                }
                {props.toShow &&
                    resultObject.services!.map((sub: any, Index: any) => (
                        <span style={{ marginRight: "4px" }}>
                            {typeof sub.hint === "string" &&
                                sub.hint.includes("WARNING:")
                                ? null
                                : warningCheckFalse(sub.hint)}
                            {Index + 1 !== resultObject.services.length ? (
                                <span>,</span>
                            ) : (
                                <> </>
                            )}
                        </span>
                    ))}
            </span>
        </React.Fragment>
    );
};

function GetIcon(data: IncludedServiceData): IconDefinition {
    let iconClass: IconDefinition = faInfoCircle;
    switch (data.subType) {
        case IncludedServiceSubType.FreeBaggageAllowance:
            iconClass = faSuitcase;
            break;
        case IncludedServiceSubType.CarryOnBaggage:
            iconClass = faShoppingBag;
            break;
        case IncludedServiceSubType.Lunch:
            iconClass = faUtensils;
            break;
        case IncludedServiceSubType.Snack:
            iconClass = faCookieBite;
            break;
        case IncludedServiceSubType.SnacksAndBeverage:
            iconClass = faUtensils;
            break;
        case IncludedServiceSubType.NonAlcoholicDrink:
            iconClass = faCoffee;
            break;
        case IncludedServiceSubType.FreeAlcoholicDrink:
            iconClass = faGlassMartini;
            break;
        // INFLIGHT ENTERTAINMENT
        case IncludedServiceSubType.PersonalEntertainment:
            iconClass = faTv;
            break;
        case IncludedServiceSubType.InternetAccess:
            iconClass = faWifi;
            break;
        case IncludedServiceSubType.InSeatpower:
            iconClass = faPlug;

            break;
        case IncludedServiceSubType.Pet:
            iconClass = faPaw;

            break;
        case IncludedServiceSubType.Security:
            iconClass = faGavel;

            break;
        case IncludedServiceSubType.PriorityBoarding:
            iconClass = faFastForward;

            break;
        case IncludedServiceSubType.Ticket:
            iconClass = faTicketAlt;

            break;
        case IncludedServiceSubType.CheckIn:
            iconClass = faChalkboardTeacher;

            break;
        case IncludedServiceSubType.ReBooking:
            iconClass = faRedo;
            break;
        case IncludedServiceSubType.RefundAllowed:
            iconClass = faMoneyCheckEditAlt;
            break;
        case IncludedServiceSubType.PriorityCheckIn:
            iconClass = faUserCheck;
            break;
        case IncludedServiceSubType.FastTrack:
            iconClass = faTachometerFastest;
            break;
        case IncludedServiceSubType.ChangeAfterDeparture:
            iconClass = faUndoAlt;
            break;
        case IncludedServiceSubType.ChangeBeforeDeparture:
            iconClass = faUndoAlt;
            break;
        case IncludedServiceSubType.ChangeInCaseOfNoShow:
            iconClass = faMapMarkerQuestion;
            break;
        case IncludedServiceSubType.OpenReturnDate:
            iconClass = faExchange;
            break;
        case IncludedServiceSubType.Stopover:
            iconClass = faMapSigns;
            break;
        default:
            switch (data.type) {
                case IncludedServiceMainType.Baggage:
                    iconClass = faSuitcase;
                    break;
                case IncludedServiceMainType.Meal:
                    iconClass = faUtensils;
                    break;
                case IncludedServiceMainType.InFlightEntertainment:
                    iconClass = faTv;
                    break;
                case IncludedServiceMainType.Miles:
                    iconClass = faMedium;
                    break;
                case IncludedServiceMainType.Transfer:
                    iconClass = faRandom;

                    break;
                case IncludedServiceMainType.Seat:
                    iconClass = faCouch;

                    break;
                case IncludedServiceMainType.Other:
                    iconClass = faCircle;
                    break;
            }
    }
    return iconClass;
}
export default incServices;
