import { DeployInfo } from "hooks/GetAppInfo";
import { AgentFrontendSettings, b2cSettingsText, BaseApiResponseMetaData, StartPage, WebAccessEnum } from "WebApiClient";
import { defaultIconData } from "./iconData";

const DefaultFrontendSettings: AgentFrontendSettings = {
  showBookingManagerTab: false,
  showFareQueryTab: false,
  b2cSettingsText: "{\"HotelTab\":\"None\",\"AirPortSearchType\":\"Most\",\"ShowLanguageDecimalTabB2C\":\"English\",\"ShowExtraAncillariesTabB2C\":\"On\",\"ShowDateInput\":\"Right\",\"ShowDefaultFilter\":\"On\",\"CabinClass\":{\"Economy\":{\"Checked\":true,\"preSelect\":true},\"Premium\":{\"Checked\":true,\"preSelect\":false},\"Business\":{\"Checked\":true,\"preSelect\":false},\"First\":{\"Checked\":false,\"preSelect\":false}},\"TermsConditionsLink\":\"https://dev.flight.agentplus.io\",\"ShowBackgroundModalColor\":\"Off\",\"showMarqueeText\":\"The flight will be delayed for 4 hours suraj\",\"showMarqueeFrom\":\"2024-05-31T10:55\",\"showMarqueeTo\":\"2024-05-30T10:58\",\"showBrandName\":\"On\",\"showAssistance\":\"On\",\"ConfirmationText\":\"<p>Thank you for booking with Everest Travel.\\n#Booking Reference#\\nYou will receive a confirmation email soon.</p>\",\"priceOption\":\"pricePerPerson\",\"Style\":\"body{\\ncolor:\\\"black\\\"\\n}\\n\"}",
  otherSettingsAsKeyValueText: "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse",
  startPage: StartPage.Query,
  enableCalculationDeugger: false,
  logoID: "",
  waitScreenID: "", 
  iconDataSettings:defaultIconData,
  locale: "DE",
  dateFormat: "DD.MM.YYYY",
  preferredCountry: "DE",
  bookingManagerWebAccess: WebAccessEnum.User,
  customerProfileData: undefined,
  allowedBookingManagerGlobalFeatures: [],
  budgetInfo: undefined,
  currency: "",
  paymentDataSettings: ""
};

const DefaultB2cSettings: b2cSettingsText = {
  HotelTab: "All",
  AirPortSearchType: "All",
  ShowExtraAncillariesTabB2C: "On",
  ShowLanguageDecimalTabB2C: "English",
  ShowDateInput: "Left",
  CabinClass: {
    Economy: {
      Checked: true,
      preSelect: true
    },
    Premium: {
      Checked: true,
      preSelect: false
    },
    Business: {
      Checked: true,
      preSelect: false
    },
    First: {
      Checked: true,
      preSelect: false
    }
  },
  showMarqueeFrom: "2024-05-20T11:54",
  showMarqueeText: "",
  showMarqueeTo: "2024-05-20T11:55",
  priceOption: "pricePerPerson",
  TermsConditionsLink: "",
  ShowBackgroundModalColor: "Off",
  showBrandName: "On",
  showAssistance: "On",
  ConfirmationText: "<p>Thank you for booking with Everest Travel.\n#Booking Reference#\nYou will receive a confirmation email soon.</p>",
  Style: "",
  showUpsellB2B:"On",
  showUpsell:"On" ,
  ShowDefaultFilter: "On",
  showFilterPosition: "Right",
  filtersOrderB2B: [
    {  label: "Stops", checked: true, order: 1 },
    { label: "Baggage included", checked: true, order: 2 },
    {  label: "Airports", checked: true, order: 3 },
    { label: "Airlines", checked: true, order: 4 },
    { label: "Alliances", checked: true, order: 5 },
    { label: "Departure time", checked: true, order: 6 },
    { label: "Arrival time", checked: true, order: 7 },
    { label: "Cabin class", checked: true, order: 8 },
    { label: "Fare type", checked: true, order: 9 },
    { label: "QueryPCC", checked: true, order: 10 },
    { label: "BookingPCC", checked: true, order: 11 },
    { label: "Source", checked: true, order: 12 },
    { label: "Allotments", checked: true, order: 13 },
    { label: "Included services", checked: true, order: 14 },
  ],
  filtersOrderB2C: [
    { label: "Departure time", checked: true, order: 1 },
    { label: "Arrival time", checked: true, order: 2 },
    {  label: "Stops", checked: true, order: 3 },
    { label: "Baggage included", checked: true, order: 4 },
    { label: "Baggage type", checked: true, order: 5 },
    {  label: "Airports", checked: true, order: 6 },
    { label: "Cabin class", checked: true, order: 7 },
    { label: "Allotments", checked: true, order: 8 },
    { label: "Included services", checked: true, order: 9 },
    { label: "Alliances", checked: true, order: 10 },
    { label: "Airlines", checked: true, order: 11 },
  ]
};

export const DefaultSessionState: SessionState = {
  isAdmin: false,
  isLoggedIn: false,
  webID: '',
  JwtToken: "",
  WebName: "",
  FrontendSettings: DefaultFrontendSettings,
  B2cSettings: DefaultB2cSettings,
  paymentDataSetting: "",
  ShowCustomerWaitScreen: false,
  ShowGeneralWaitScreen: false,
  WaitscreenProgress: { Percentage: 0, Label: "" },
  VersionDifferenceToBackend: false,
  NewBackendVersion: "",
  Transactions: [],
  DeployInfo: undefined
};

export interface SessionState {
  readonly webID: String;
  readonly isAdmin: boolean;
  readonly isLoggedIn: boolean;
  readonly JwtToken: string;
  readonly WebName: string;
  readonly paymentDataSetting: string;
  readonly FrontendSettings: AgentFrontendSettings;
  readonly B2cSettings: b2cSettingsText
  readonly WaitscreenProgress: WaitScreenProgress;
  readonly ShowCustomerWaitScreen: boolean;
  readonly ShowGeneralWaitScreen: boolean;
  readonly VersionDifferenceToBackend: boolean;
  readonly NewBackendVersion: string;
  readonly Transactions: BaseApiResponseMetaData[];
  readonly DeployInfo: DeployInfo | undefined
};

export interface TransactionIDInfo {
  readonly TransactionID: string;
  readonly Time: string;
  readonly Label: string;
  readonly Success: boolean;
};

export interface WaitScreenProgress {
  Percentage: number;
  Label: string;
};

export interface DeepLoginData {
  WebID: string;
  Deeplink: string;
  Query: string
};
