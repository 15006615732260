import {
  FaresFilterState,
  FilterCriteria,
  FreeBaggageFilterCriteria,
  IncludedServiceMainFilterCriteria,
  LegMaxStopFilterCriteria,
  LegTimeFilterCriteria,
} from "components/AvailableFares/types/AvailableFaresTypes";
import { GetMaingroupName } from "components/Shared/IncludedServiceIcon";
import {
  AvailableFareModel,
  DefaultAvailFaresfilterSettings,
} from "WebApiClient";

interface BaggageType {
  Label: string;
  Value: string;
  Checked: boolean;
}

export function BuildFaresFilter(
  fares: AvailableFareModel[],
  filter: DefaultAvailFaresfilterSettings
): FaresFilterState {
  let departureAirports: FilterCriteria[] = [];
  let arrivalAirports: FilterCriteria[] = [];
  let airlines: FilterCriteria[] = [];
  let alliances: FilterCriteria[] = [];
  let legsMaxStopAmount: LegMaxStopFilterCriteria;
  let legDepartureTimes: LegTimeFilterCriteria[] = [];
  let legArrivalTimes: LegTimeFilterCriteria[] = [];
  let includedServices: IncludedServiceMainFilterCriteria[] = [];
  let fareTypes: FilterCriteria[] = [];
  let allotmentTypes: FilterCriteria[] = [];
  let queryPccs: FilterCriteria[] = [];
  let bookingPccs: FilterCriteria[] = [];
  let sources: FilterCriteria[] = [];
  let cabinClasses: FilterCriteria[] = [];
  let baggageType: FilterCriteria[] = [];
  const AllotmentTypesActive: boolean = true;
  const AllAirportsActive: boolean = true;
  const AllAirlinesActive: boolean = true;
  const AllCabinClassesActive: boolean = true;
  const AllAlliancesActive: boolean = true;
  const AllIncludedServicesActive: boolean = false;
  const AllFareTypesActive: boolean = true;
  const AllQueryPCCActive: boolean = true;
  const AllBookingPCCActive: boolean = true;
  const AllTicketPCCActive: boolean = true;
  const AllSourceActive: boolean = true;
  const AllBaggageType: boolean = true;


  function standardizeBaggageType(value: string): string {
    const match = value.match(/^(\d*)([a-zA-Z]*)$/);
    if (!match) return value; // If no match, return the value as is
    let [_, numberPart, typePart] = match;

    // Remove leading zeros in the number part (e.g., "01" -> "1")
    numberPart = numberPart ? parseInt(numberPart, 10).toString() : "";

    // Convert the baggage type part to uppercase
    typePart = typePart.toUpperCase();
 
    // Normalize similar baggage types ("P" and "PC" -> "PC")
    if (typePart === "P" || typePart === "PC") {
      typePart = "PC";
    } else if (typePart === "K") {
      typePart = "K";
    }

    // Return the standardized value (e.g., "1PC" or "20K")
    return numberPart + typePart;
  }

  // Updated function to add baggage type only if it's not already in the list
  function addBaggageTypeIfNotExists(
    baggageType: BaggageType[],
    fare: { freeBaggageAllowance: string }
  ) {
    // Check if fare.freeBaggageAllowance is empty or not
    if (!fare.freeBaggageAllowance) {
      // Add a "No Baggage" entry if needed
      if (!baggageType.some((x) => x.Value === "")) {
        baggageType.push({ Label: "", Value: "", Checked: true });
      }
      return; // Exit if there's no baggage allowance
    }

    // Standardize the fare's baggage allowance for both label and value
    const standardizedBaggage = standardizeBaggageType(fare.freeBaggageAllowance);

    // Check if the standardized baggage type exists in the baggageType array
    if (
      standardizedBaggage && // Ensure it's not empty
      !baggageType.some(
        (x) =>
          standardizeBaggageType(x.Value) === standardizedBaggage || // Compare values
          standardizeBaggageType(x.Label) === standardizedBaggage // Compare labels
      )
    ) {
      // Add the baggage type if it doesn't exist
      baggageType.push({
        Label: standardizedBaggage, // Use the standardized label
        Value: standardizedBaggage, // Use the standardized value for consistency
        Checked: true,
      });
    }
  }

  fares.forEach((fare) => {
    fare.legs.forEach((leg, legIndex) => {
      let faretype = "Unknown";
      if (leg.fareTypeInformation.salesPreferenceDisplayName) {
        faretype = leg.fareTypeInformation.salesPreferenceDisplayName;
      } else {
        faretype = leg.fareTypeInformation.originalFareType;
      }
      if (!fareTypes.some((x) => x.Value === faretype)) {
        fareTypes.push({ Label: faretype, Value: faretype, Checked: true });
      }
    });


    if (!baggageType.some((x) => x.Value == fare.freeBaggageAllowance)) {
      addBaggageTypeIfNotExists(baggageType, fare);
    }

    fare.fareInfoBox?.queryPccs?.forEach((e) => {
      if (!queryPccs.some((x) => x.Value === e)) {
        queryPccs.push({ Label: e, Value: e, Checked: true });
      }
    });
    fare.fareInfoBox?.bookingPccs?.forEach((e) => {
      if (!bookingPccs.some((x) => x.Value === e)) {
        bookingPccs.push({ Label: e, Value: e, Checked: true });
      }
    });

    fare.fareInfoBox?.sources?.forEach((e) => {
      if (!sources.some((x) => x.Value === e)) {
        sources.push({ Label: e, Value: e, Checked: true });
      }
    });

    fare.legs.forEach((leg, legIndex) => {
      if (!legDepartureTimes.some((x) => x.LegIndex === legIndex)) {
        legDepartureTimes.push({ LegIndex: legIndex, MinHour: 0, MaxHour: 24 });
      }
      if (!legArrivalTimes.some((x) => x.LegIndex === legIndex)) {
        legArrivalTimes.push({ LegIndex: legIndex, MinHour: 0, MaxHour: 24 });
      }
      if (leg.platingCarrier) {
        if (leg.platingCarrier.code && leg.platingCarrier.code !== "") {
          if (!airlines.some((x) => x.Value === leg.platingCarrier!.code!)) {
            airlines.push({
              Value: leg.platingCarrier!.code!,
              Label: `${leg.platingCarrier!.hint} (${leg.platingCarrier!
                .code!})`,
              Checked: true,
            });
          }
        }
        if (
          leg.platingCarrier.allianceCode && leg.platingCarrier!.allianceCode !== ""
        ) {
          if (
            !alliances.some((x) => x.Value === leg.platingCarrier!.allianceCode)
          ) {
            alliances.push({
              Value: leg.platingCarrier.allianceCode!,
              Label: `${leg.platingCarrier!.allianceName}`,
              Checked: true,
            });
          }
        }
      }

      leg.connections!.forEach((connection) => {
        connection.segments!.forEach((segment, index) => {
          const isfirstSegment = index === 0;
          const isLastSegment = index === connection.segments!.length - 1;

          if (!cabinClasses.some((x) => x.Value === segment.cabinClassName)) {
            cabinClasses.push({
              Value: segment.cabinClassName!,
              Label: segment.cabinClassName!,
              Checked: true,
            });
          }

          const segmentAllotment = segment.allotmentInformation;
          if (segmentAllotment) {
            segmentAllotment.types.forEach((t) => {
              if (!allotmentTypes.some((x) => x.Value === t)) {
                allotmentTypes.push({ Value: t, Label: t, Checked: true });
              }
            });
          }

          if (isfirstSegment) {
            if (
              !departureAirports.some(
                (x) => x.Value === segment.departureAirport!.iata!
              )
            ) {
              departureAirports.push({
                Value: segment.departureAirport!.iata!,
                Label: `${segment.departureAirport!
                  .name!} (${segment.departureAirport!.iata!})`,
                Checked: true,
              });
            }
          }

          if (isLastSegment) {
            if (
              !arrivalAirports.some(
                (x) => x.Value === segment.arrivalAirport!.iata!
              )
            ) {
              arrivalAirports.push({
                Value: segment.arrivalAirport!.iata!,
                Label: `${segment.arrivalAirport!
                  .name!} (${segment.arrivalAirport!.iata!})`,
                Checked: true,
              });
            }
          }

          if (segment.includedServices) {
            segment.includedServices.forEach((mainGroup) => {
              if (mainGroup.services) {
                mainGroup.services.forEach((subservice) => {
                  if (
                    !includedServices.some(
                      (x) => x.Maingroup === mainGroup.type
                    )
                  ) {
                    includedServices.push({
                      Maingroup: mainGroup.type,
                      MainLabel: GetMaingroupName(mainGroup.type),
                      Values: [],
                    });
                  }
                  if (
                    !includedServices
                      .filter((x) => x.Maingroup === mainGroup.type)[0]
                      .Values.some(
                        (v) =>
                          v.Value.toLowerCase() ===
                          subservice.hint!.toLowerCase()
                      )
                  ) {
                    includedServices
                      .filter((x) => x.Maingroup === mainGroup.type)[0]
                      .Values.push({
                        Icon: subservice,
                        Value: subservice.hint!,
                        Label: subservice.hint!,
                        Checked: false,
                      });
                  }
                });
              }
            });
          }
        });
      });
    });

    includedServices.sort((x, y) => (x.Maingroup > y.Maingroup ? 1 : -1));
    includedServices.forEach((group) => {
      group.Values.sort((x, y) => (x.Label > y.Label ? 1 : -1));
    });

  });

  const generateDefaultMaxStopSelection = (numberOfStops: number): { [key: number]: boolean } => {
    const defaultMaxStopSelection: { [key: number]: boolean } = {};
    for (let i = 0; i < numberOfStops; i++) {
      defaultMaxStopSelection[i] = false; // Set each stop to false initially
    }
    return defaultMaxStopSelection;
  };

  const result: FaresFilterState = {
    LegsMaxStopAmount: {
      MaxStopSelection: generateDefaultMaxStopSelection(0),
    },
    CabinClasses: cabinClasses,
    SelectAllAirlinesActive: AllAirlinesActive,
    Airlines: airlines,
    SelectAllAirportsActive: AllAirportsActive,
    DepartureAirports: departureAirports,
    ArrivalAirports: arrivalAirports,
    Alliances: alliances,
    IncludedService: includedServices,
    ArrivalTime: legArrivalTimes,
    DepartureTime: legDepartureTimes,
    FareTypes: fareTypes,
    BaggageType: baggageType,
    FreeBaggage: FreeBaggageFilterCriteria.All,
    SelectAllAlliancesActive: AllAlliancesActive,
    SelectAllCabinClassesActive: AllCabinClassesActive,
    SelectAllFareTypesActive: AllFareTypesActive,
    SelectAllQueryPCCActive: AllQueryPCCActive,
    SelectAllBookingPCCActive: AllBookingPCCActive,
    SelectAllTicketPCCActive: AllTicketPCCActive,
    SelectAllSourceActive: AllSourceActive,
    SelectAllIncludedServicesActive: AllIncludedServicesActive,
    QueryPCCs: queryPccs,
    BookingPCCs: bookingPccs,
    Sources: sources,
    SelectAllAllotmentTypesActive: AllotmentTypesActive,
    AllotmentTypes: allotmentTypes,
    SelectAllBaggageType: AllBaggageType
  };
  return result;
}
